import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ApiService } from 'src/app/services/api.service';

declare var window: any;

@Component({
  selector: 'politica-de-privacidade-page',
  templateUrl: './politica-de-privacidade-page.component.html',
  styleUrls: ['./politica-de-privacidade-page.component.scss']
})
export class PoliticaDePrivacidadePage implements OnInit, AfterViewInit {

  public loader   = false;
  private apelido = "politicas-de-privacidade";
  public response:any = {
    data: "",
    status: null
  };
  public page    = null;
  public fotos   = []; 
  public params  = {};
  public equipe  = [];
  public itensBreadcrumbs = [];

  constructor(
    private api:ApiService,
    private app:AppService
  ) { }
  /**
   * 
   * Retorna os dados
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      
      this.api.conteudo().page(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = JSON.parse(this.page.params);

            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Políticas de Privacidade"
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop();
    this.getData();
    this.setItensBreadcrumbs();
  }
  /**
   * 
   * Inicializa depois de carregar a VIEW
   * 
   */
  ngAfterViewInit():void{}
 
}
