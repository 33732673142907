import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-dicas',
  templateUrl: './dicas.component.html',
  styleUrls: ['./dicas.component.scss']
})
export class DicasComponent implements OnInit,AfterViewInit {

  @Input("template") template         = "";
  @Input("data") data:any             = {};
  @Input("classCustom") classCustom   = "";
  @ViewChild("slider") slider:ElementRef;

  constructor(){ }
    
  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false,
      arrows: false,
      centerMode: true,
      centerPadding: '20px',
      responsive: [{
          breakpoint: 1224,
          settings: {
              slidesToShow: 3,
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    });
    $(this.slider.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * Inicializa depois da VIEW carregar
   * 
   */
  ngAfterViewInit():void{
    setTimeout(() => {
      this.initSlider(); 
    },200);
    
  }

}
