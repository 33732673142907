import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acomodacao-item-two',
  templateUrl: './acomodacao-item-two.component.html',
  styleUrls: ['./acomodacao-item-two.component.scss']
})
export class AcomodacaoItemTwoComponent implements OnInit {

  @Input("data") data = null;

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
