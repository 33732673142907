<div class="acomodacao-form-ajuda">
      <div class="header">
            <span>Envie uma mensagem com a sua dúvida.</span>
      </div> 
      <div class="content">
            <form class="form" (submit)="send()">    
                  <div class="form-group">
                        <input type="text" [(ngModel)]="dataItem.nome" placeholder="Nome:" name="nome" />
                  </div>
                  <div class="form-group">
                        <input type="text" [(ngModel)]="dataItem.email" placeholder="E-mail:" name="email" />
                  </div>
                  <div class="form-group">
                        <input type="text" [(ngModel)]="dataItem.telefone" placeholder="Telefone:" name="telefone" mask="(99) 99999-9999" />
                  </div>
                  <div class="form-group">  
                        <textarea [(ngModel)]="dataItem.mensagem" placeholder="Escreva a sua dúvida..." name="mensagem"></textarea>
                        <button type="submit">
                              <i class="fas fa-paper-plane"></i>
                        </button>
                  </div>
            </form>      
      </div>      
</div>
<loader *ngIf="loader"></loader>