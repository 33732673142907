<div class="modulo modulo-destino">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <div class="content"> 
                        <a routerLink="/destino" title="Destino">
                              <h2>{{data.titulo}}</h2>
                              <div class="detalhe"><span></span><span></span><span></span></div>
                              <h3>{{data.subtitulo}}</h3>
                        </a>
                  </div>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container">
                  <div class="row">
                        <div class="col-md-4 col-center">
                              <div class="box">
                                    <div class="header">
                                          <h4>{{data.params.titulo}}</h4>
                                          <div class="linha"></div>
                                    </div>      
                                    <div class="content">
                                          <article [innerHtml]="data.params.texto | safeHtml"></article>
                                    </div>   
                                    <div class="footer">
                                          <div class="linha"></div>
                                          <a routerLink="/destino" title="Destino">
                                                Ver mais
                                          </a>      
                                    </div>      
                              </div>      
                        </div>   
                        <div class="col-md-8 col-right">
                              <section class="section section-fotos">
                                    <galeria-light [fotos]="data.fotos" template="modulo-destino"></galeria-light>
                              </section>
                        </div>      
                  </div>      
            </div>
      </div>       
</div>      
