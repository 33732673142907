<div class="page page-sobre" itemtype="https://schema.org/AboutPage">  
      <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
        <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0">
          <breadcrumbs home="false" [itens]="itensBreadcrumbs"></breadcrumbs>
        </div> 
        <div class="page-title">
          <div class="container">
            <h1 itemprop="name">{{post.post.titulo}}</h1>
            <h2 *ngIf="post.post.mostrar_subtitulo">{{post.post.subtitulo}}</h2>
          </div>  
        </div>  
        <div class="page-content">
          <section class="section section-descricao">
            <div class="container">
              <div class="autor">
                <strong>Escrito por: {{post.post.autor}}</strong>
              </div> 
              <article class="descricao" [innerHtml]="post.post.descricao | safeHtml"></article>       
            </div>
          </section>
          <section class="section section-fotos" *ngIf="post.images.length > 0">
            <div class="section-title">
              <div class="container">
                <h3>{{chamadaTexto}}</h3>
              </div>
            </div>
            <blog-slider [data]="post.images"></blog-slider>
          </section>  
        </div> 
      </div> 
      <div *ngIf="response.status == 500"> 
        <div class="container"> 
          <page-error [data]="response.data" (reload)="getData()"></page-error>
        </div>  
      </div>  
</div>  
<loader [hidden]="!loader"></loader> 
        
