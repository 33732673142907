<div class="modulo modulo-banner-destaque banner-slider-slick animated fadeIn">
    <div #banner class="banner-slider-slick-content">
        <div *ngFor="let i of data.banners;let y = index">
            <div class="item slider-item" *ngIf="!i.link_ativo">
                <div class="hero-paralax"> 
                <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
                </div>
            </div>  
            <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
                <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
                <div class="item slider-item">
                    <div class="hero-paralax"> 
                    <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
                    </div>
                </div>  
                </a>
                <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
                <div class="item slider-item">
                    <div class="hero-paralax"> 
                    <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
                    </div>
                </div>  
                </a> 
                <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
                <div class="item slider-item">
                    <div class="hero-paralax"> 
                    <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
                    </div>
                </div>  
                </a>   
            </div>
        </div>
    </div>
</div>    