<div class="modulo modulo-rede-social">
  <div [ngSwitch]="template">
    <div *ngSwitchCase="null" class="default">
      <div class="content">
        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
          <h3>{{data.titulo}}</h3>
        </div>
        <div class="modulo-content modulo-content-footer">
          <ul>
            <li>
              <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Brocker Turismo">
                <i class="fab fa-facebook-square"></i>
              </a> 
            </li> 
            <li>
              <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Brocker Turismo">
                <i class="fab fa-instagram"></i>
              </a>  
            </li>
          </ul>  
        </div>  
      </div>
    </div>
    <div *ngSwitchCase="'footer'">
      <ul>
        <li>
          <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Brocker Turismo">
            <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/redes-sociais/facebook.svg" alt="Facebook - Brocker Turismo" />
          </a> 
        </li> 
        <li> 
          <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Brocker Turismo">
            <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/redes-sociais/instagram.svg" alt="Instagram - Brocker Turismo" />
          </a>  
        </li>
        <li>
          <a href="{{data.params.linkedin}}" target="__blank" title="LinkedIn - Brocker Turismo">
            <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/redes-sociais/linkedin.svg" alt="LinkedIn - Brocker Turismo" />
          </a>  
        </li> 
        <li>
          <a href="{{data.params.trip_advisor}}" target="__blank" title="TripAdvisor - Brocker Turismo">
            <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/redes-sociais/trip-advisor.svg" alt="TripAdvisor - Brocker Turismo" />
          </a>  
        </li> 
      </ul> 
    </div>
    <div *ngSwitchCase="'page-contato'" class="default-contato">
      <div class="content">
        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
          <h3>{{data.titulo}}</h3>
        </div>
        <div class="modulo-content modulo-content-footer">
          <ul>
            <li>
              <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Brocker Turismo">
                <i class="fab fa-facebook-square"></i>
              </a> 
            </li> 
            <li>
              <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Brocker Turismo">
                <i class="fab fa-instagram"></i>
              </a>  
            </li>
          </ul>  
        </div>  
      </div>
    </div>
  </div>  
</div>
