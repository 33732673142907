import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acomodacao-quartos',
  templateUrl: './acomodacao-quartos.component.html',
  styleUrls: ['./acomodacao-quartos.component.scss']
})
export class AcomodacaoQuartosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
