import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { DataService } from 'src/app/services/data.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'cupom-desconto',
  templateUrl: './cupom-desconto.component.html',
  styleUrls: ['./cupom-desconto.component.scss']
})
export class CupomDescontoComponent implements OnInit {

  public loader       = false;
  public dataItem:any = {};
  public total        = 0;
  @Input("message") message = null;

 
  constructor(
    private app: AppService,
    private api: ApiService,
    private validator: ValidatorService,
    private data: DataService,
    private cart: CartService
  ){ }

  /**
   * 
   * Valida o cupom
   * 
   */
  validar(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.codigo,"codigo","- Informe o Cupom.");

      if(!this.validator.passes()){

        this.app.info(this.validator.getMessagesHtml());
        return false;

      }

      this.loader = true;

      this.api.loja().cupom().validar(this.dataItem.codigo.trim()).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.total = response.data.total;

          if(this.total == 0){
            this.app.info(this.message);
            this.cart.emitirUpdateDesconto.emit(0);
            this.cart.emitirUpdateCupom.emit(response.data); 
          }else{
            this.cart.emitirUpdateDesconto.emit(this.total);
            this.cart.emitirUpdateCupom.emit(response.data); 
          }

        }else{

          this.app.info(this.message);  

        }
        

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);
        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;
      this.app.info(e.message);

    }

  }
  /***
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      codigo: ""
    }

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.initDataItem();
  
  }

}
