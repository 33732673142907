import { Component, OnInit, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GtagService } from '../../services/gtag.service';
import { FbService } from '../../services/fb.service';
import { ApiService } from 'src/app/services/api.service';

declare var window:any;

@Component({
  selector: 'sobre-page',
  templateUrl: './sobre-page.component.html',
  styleUrls: ['./sobre-page.component.scss']
})
export class SobrePage implements OnInit, AfterViewInit,OnDestroy {

  public loader   = false;
  private apelido = "sobre"; 
  public response:any = {
    data: "",
    status: null
  };
  public page     = null;
  public fotos    = []; 
  public params:any = {};
  public valores  = [];
  public clientes = [];
  public itensBreadcrumbs = [];
  public isSendAnalytics    = false;
  public foto = {
    imagem: "/images/sem-foto/default.jpg",
    titulo: "Sobre a Pousada"
  }
  public openGaleria = new EventEmitter();

  constructor(
    private api:ApiService,
    private app: AppService,
    private gtag: GtagService,
    private fb: FbService 
  ) { }
  

  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true; 
      
      this.api.conteudo().page(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos     = response.data.page.fotos;

            this.setFoto();

            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = { 
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Sobre a Pousada"
    });

  }
  /**
   * 
   * Set foto
   * 
   */
  setFoto(){

    let foto = null;

    for(let i=0;i < this.fotos.length;i++){

      if(this.fotos[i].capa){

        this.foto = this.fotos[i];
        break;

      }

    }

    if(foto != null){
      this.foto = foto;
    }

  }
  /**
   * 
   * Open galeria
   * 
   */
  _openGaleria(){

    this.openGaleria.emit();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop();
    this.getData(); 
    this.setItensBreadcrumbs();
  }
  ngAfterViewInit(): void {
    
  }
  ngOnDestroy(): void {
    this.isSendAnalytics = false;
  }

}
