<div [ngSwitch]="template">
  <section class="modulo modulo-contato" *ngSwitchCase="null">
    <div class="container">
      <div class="modulo-title" *ngIf="data.mostrar_titulo">
        <div class="linha linha-left">
        </div>
        <h2>{{data.titulo}}</h2>
        <div class="linha linha-right">
        </div>
      </div> 
      <div class="modulo-content">
         <h3 class="descricao" *ngIf="data.params.descricao.length > 0">{{data.params.descricao}}</h3>
         <div class="row">
            <div class="col-lg-5 col-md-6">
              <div class="item-contato">
                <figure>
                  <img src="/images/modulos/contato/email.png" alt="Email - Bustour" />
                  <span class="bg"></span>
                </figure>
                <span class="text">
                  {{data.params.email}}
                </span>  
              </div> 
              <div class="content-form">
                <form class="form" (submit)="send()">
                  <div class="form-group">
                    <input type="text" [(ngModel)]="dataItem.nome" placeholder="Nome completo" name="nome" />
                  </div>
                  <div class="form-group telefone-input">
                    <div class="telefone-ddd">
                      <input type="text" [(ngModel)]="dataItem.telefone_ddd" placeholder="DDD" name="telefone_ddd" mask="99" (input)="onInputDDD($event)" />
                    </div>
                    <div class="telefone-numero">
                      <input type="text" [(ngModel)]="dataItem.telefone_numero" placeholder="Telefone" name="telefone_numero" mask="99999-9999" #telefoneNumero />
                    </div>  
                  </div> 
                  <div class="form-group">
                    <input type="text" [(ngModel)]="dataItem.email" placeholder="E-mail" name="email" />
                  </div> 
                  <div class="form-group">
                    <textarea type="text" [(ngModel)]="dataItem.mensagem" placeholder="Mensagem" name="mensagem" rows="7"></textarea>
                  </div> 
                  <div class="form-group">
                    <button type="submit" class="degrade-light-blue btn-custom" [disabled]="loader">
                      <span *ngIf="!loader">ENVIAR</span>
                      <span *ngIf="loader">ENVIANDO...</span>
                    </button>  
                  </div>  
                </form> 
              </div>  
            </div>  
            <div class="col-lg-4 col-md-6">
              <div class="item-contato">
                <figure>
                  <img src="/images/modulos/contato/location.png" alt="Localização - Bustour" />
                  <span class="bg"></span>
                </figure>
                <span class="text">
                  LOJA BUSTOUR
                </span>  
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="item-contato">
                <figure>
                  <img src="/images/modulos/contato/telefone-two.png" alt="Telefone - Bustour" />
                  <span class="bg"></span>
                </figure>
                <span class="text">
                  {{data.params.telefone}}
                </span>  
              </div>
            </div>  
         </div> 
      </div>  
    </div>  
  </section> 
  <section *ngSwitchCase="'footer-contato'" class="modulo modulo-footer-contato">
    <ul>
      <li>
        <a routerLink="contato" title="Contato">
          <h3>Precisa de Ajuda?</h3>
          <h4>Entre em Contato</h4>
        </a>  
      </li>
      <li>
        <div class="item">
          <div class="content-one">
            <figure>
              <img src="/assets/images/telefones/telefone-azul.png" alt="Telefone Brocker Eventos" />
            </figure>  
          </div>
          <div class="content-two">
            <div class="telefone">
              <span>+55 {{data.params.telefone}}</span>
            </div>  
          </div>  
        </div>  
      </li>
      <li>
        <div class="item">
          <div class="content-one">
            <figure>
              <img src="/assets/images/telefones/whatsapp.png" alt="Whatsapp Brocker Eventos" />
            </figure>  
          </div>
          <div class="content-two">
            <div class="telefone">
              <span>{{data.params.telefone_whatsapp}}</span>
            </div>
          </div>  
        </div> 
      </li>
    </ul>  
  </section>  
  <section *ngSwitchCase="'page-contato'" class="modulo modulo-footer-contato pg-contato">
    <div class="modulo-content">
      <h4>
        <img src="/images/icons/aextour/telefone.svg" alt="Telefone" />
        {{data.params.telefone}}
      </h4>
      <h4>
        <img src="/images/icons/aextour/telefone.svg" alt="Telefone" />
        {{data.params.celular}}
      </h4>
      <h4> 
        <img src="/images/icons/aextour/email.svg" alt="Email" />
        {{data.params.email}}
      </h4>
    </div> 
  </section> 
  <section *ngSwitchCase="'contato-home'" class="modulo modulo-contato-home" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <div class="container">
        <figure class="img-brocker">
          <img src="/images/modulo/contato/equipe.jpg" alt="Brocker Turismo" /> 
        </figure>  
        <ul>
          <li (click)="open()">
            <div class="icon">
              <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/whatsapp-two.svg"  alt="WhatsApp - Brocker Turismo" />
            </div>  
            <div class="text">
              <strong>WhatsApp</strong> 
              <span>Entre em contato</span>
            </div> 
          </li>
          <li class="email" (click)="toContato()"> 
            <div class="icon">
              <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/contato/email.svg"  alt="E-mail - Brocker Turismo" />
            </div>  
            <div class="text">
              <strong>E-mail</strong> 
              <span>Enviar um e-mail</span>
            </div> 
          </li>
          <li>
            <div class="icon">
              <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/contato/telefone.svg"  alt="Telefone - Brocker Turismo" />
            </div>  
            <div class="text">
              <a href="tel:{{data.params.telefone}}" title="Telefone - Brocker Turismo" (click)="_ligacao()">
                <strong>Telefone</strong>
                <span>Solicite uma ligação</span>
              </a>  
            </div>  
          </li>
        </ul>  
      </div>  
    </div> 
  </section>
  <section *ngSwitchCase="'footer'" class="modulo default-footer" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-titulo">
      <h3 class="modulo-title">Atendimento</h3>
    </div>
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <div class="item item-email">
        <ul>
          <li>
            <span itemprop="email">{{data.params.telefone}}</span> 
          </li> 
          <li>
            <span itemprop="telephone">{{data.params.celular}}</span> 
          </li>
          <li>
            <span itemprop="telephone">{{data.params.telefone_secundario}}</span> 
          </li> 
          <li class="li-email">
            <span itemprop="email">{{data.params.email}}</span> 
          </li>  
        </ul> 
        <strong class="text-info">{{data.params.texto}}</strong> 
      </div> 
    </div> 
  </section>
  <section *ngSwitchCase="'email'" class="modulo default-email" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-titulo">
      <h3 class="modulo-title">E-mail:</h3>
    </div>
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <div class="item item-email">
        <a href='mailto:{{data.params.email}}' title="E-mail: {{data.params.email}}">
          {{data.params.email}}
        </a>
      </div> 
    </div> 
  </section>
  <section *ngSwitchCase="'telefones'" class="modulo default-telefones" itemscope itemtype="https://schema.org/Organization">
    <div class="modulo-titulo">
      <h3 class="modulo-title">Telefones:</h3>
    </div>
    <div class="modulo-content" itemprop itemtype="https://schema.org/ContactPoint">
      <div class="item item-phone">
        <a href='tel:{{data.params.telefone}}' title="E-mail: {{data.params.telefone}}">
          <i class="fas fa-phone"></i>
          <span>{{data.params.telefone}}</span>
        </a>
      </div> 
      <div class="item item-phone">
        <a href='tel:{{data.params.celular}}' title="E-mail: {{data.params.celular}}">
          <i class="fas fa-phone"></i>
          <span>{{data.params.celular}}</span>
        </a>
      </div> 
      <div class="item item-phone">
        <a href='tel:{{data.params.telefone_secundario}}' title="E-mail: {{data.params.telefone_secundario}}">
          <i class="fas fa-phone"></i>
          <span>{{data.params.telefone_secundario}}</span>
        </a>
      </div> 
      <div class="item item-whatsapp" (click)="open()">
        <a href="javascript::void();" title="WhatsApp: {{data.params.whatsapp}}">
          <i class="fab fa-whatsapp"></i>
          <span>{{data.params.whatsapp}}</span>
        </a>
      </div> 
    </div> 
  </section>
</div>  

