 <div class="md-select-custom not-placeholder form-custom" (click)="_getData()">
  <label>{{label}}</label> 
  <mat-select [(ngModel)]="value" name="value" #select (selectionChange)="_change()">
    <mat-select-search [formControl]="comboCtrl"></mat-select-search>
    <mat-option [value]="value"  *ngIf="response.data.length == 0 && value != null">
      {{text}}
    </mat-option> 
    <mat-option [value]="d.idHotelPousada" *ngFor="let d of comboFilter | async"> 
      {{d.hotelPousada}}
    </mat-option>
  </mat-select>
  <div class="message-loader-inline loading" *ngIf="loader">
    Carregando... Aguarde!
  </div>
  <div class="message-error-inline" *ngIf="response.status == 500">
    {{response.message}}  
  </div>  
</div>