<section class="page-home">
  <modulo posicao="banner-slider"></modulo>   
  <modulo posicao="sobre"></modulo>  
  <modulo posicao="acomodacao"></modulo>  
  <modulo posicao="destino"></modulo>   
  <modulo posicao="blog"></modulo>    
</section>   

 



