<div class="page page-acomodacao" itemtype="https://schema.org/AboutPage"> 
      <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
        <div class="page-title" *ngIf="page.mostrar_titulo">
          <div class="container">
            <h1 itemprop="name">{{page.titulo}}</h1>
            <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
          </div>  
        </div>  
        <div class="page-content">
          <section class="section section-acomodacoes">
            <div class="container">  
              <div class="item" *ngFor="let a of acomodacoes">
                <acomodacao-item-two [data]="a"></acomodacao-item-two>   
              </div>      
            </div> 
          </section>
          <section class="section section-contato">
            <div class="section-title">
              <div class="container">
                <h2>Precisa de ajuda para escolher a sua acomodação?</h2>
              </div>
            </div>  
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <acomodacao-form-ajuda></acomodacao-form-ajuda>
                </div>
                <div class="col-md-4">
                  <modulo posicao="whatsapp" template='acomodacoes'></modulo>
                </div>
                <div class="col-md-4"> 
                  <modulo posicao="parcelamento"></modulo>
                </div> 
              </div>  
            </div>  
          </section>  
        </div> 
      </div> 
      <div *ngIf="response.status == 500"> 
        <div class="container"> 
          <page-error [data]="response.data" (reload)="getData()"></page-error>
        </div>  
      </div>  
</div>  
<loader [hidden]="!loader"></loader> 
        
