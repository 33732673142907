<div class="modulo modulo-sobre" (window:resize)="_resize($event)">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2>{{data.titulo}}</h2> 
            </div> 
      </div>
      <div class='modulo-content'>  
            <div class="container">
                  <article class="descricao" [innerHtml]="data.params.texto | safeHtml"></article>     
            </div>
            <div class="clearfix"></div>
            <div class="fotos" *ngIf="data.fotos.length > 0">
                  <div class="content" #fotos>
                        <galeria-carrossel-images [data]="data.fotos"></galeria-carrossel-images>
                  </div>
            </div>  
      </div>      
</div>