import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-acomodacao',
  templateUrl: './acomodacao.component.html',
  styleUrls: ['./acomodacao.component.scss']
})
export class AcomodacaoComponent implements OnInit {

  @Input("data") data;
  @Input("template") template = null; 
  @Input("classCustom") classCustom = ""; 
  
  constructor(){ }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
