import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { RouteBase } from 'src/app/routes/base';

@Component({
  selector: 'passageiros-modal',
  templateUrl: './passageiros-modal.component.html',
  styleUrls: ['./passageiros-modal.component.scss']
})
export class PassageirosModal implements OnInit {

  public programacao = null; 
  public ids         = [];
  public loader      = false;
  public passageiros = [];

  constructor(
    public dialog: MatDialogRef<PassageirosModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    public app: AppService,
    public api: ApiService
  ){}

  /**
   * 
   * Seta a programação
   * 
   */
  setProgramacao(){

    this.programacao = this.data.programacao;

  }
  /**
   * 
   * Passageiro selected
   * 
   */
  isSelectedPassageiro(id){

    let status = false;

    for (let index = 0; index < this.ids.length; index++) {
      
      if(id == this.ids[index]){
        status = true;
        break;
      }
      
    }

    return status;
    
  }
   /**
   * 
   * Get passageiro
   * 
   */ 
  getPassageiro(passageiros,tipo){

    let data = null;

    for(let index = 0;index < passageiros.length;index++) {
      
      if(passageiros[index].tipo == tipo){

        if(!this.isSelectedPassageiro(passageiros[index].id)){ 

          data = passageiros[index];
          break;

        }

      }
      
    }

    return data;

  }
  /**
   * 
   * Set campos passageiros
   * 
   */
  setCamposPassageiros(){

    let adt                  = 0;
    let snr                  = 0;
    let inf                  = 0;
    let chd                  = 0;
    let passageiros          = [];
    let passageiros_carrinho = [];
    let passageiro           = null;  

    for(let index = 0;index < this.programacao.servicos.length;index++) {
      
      this.ids              = [];
      passageiros           = [];
      passageiros_carrinho  = this.programacao.servicos[index].passageiros; 
      passageiro            = null;
      adt                   = this.programacao.servicos[index].qtd_adt;
      snr                   = this.programacao.servicos[index].qtd_snr;
      inf                   = this.programacao.servicos[index].qtd_inf;
      chd                   = this.programacao.servicos[index].qtd_chd; 

      for(let index = 0;index < adt;index++) { 
        
        passageiro = this.getPassageiro(passageiros_carrinho,"adt");

        if(passageiro == null){
          
          passageiros.push({
            nome: "",
            sobrenome: "",
            telefone: "",
            documento: "",
            tipo: "adt",
            titular: false
          });

        }else{
          passageiros.push(passageiro);
          this.ids.push(passageiro.id); 
        }
                 
      
      }
      for(let index = 0;index < snr;index++) {

        passageiro = this.getPassageiro(passageiros_carrinho,"snr");
        
        if(passageiro == null){
          
          passageiros.push({
            nome: "",
            sobrenome: "",
            telefone: "",
            documento: "",
            tipo: "snr",
            titular: false
          });

        }else{
          passageiros.push(passageiro);
          this.ids.push(passageiro.id); 
        }
        
      }
      for(let index = 0;index < chd;index++) {

        passageiro = this.getPassageiro(passageiros_carrinho,"chd");

        if(passageiro == null){
          
          passageiros.push({
            nome: "",
            sobrenome: "",
            telefone: "",
            documento: "",
            tipo: "chd",
            titular: false
          });
        
        }else{
          passageiros.push(passageiro);
          this.ids.push(passageiro.id); 
        } 
          
      }
      for(let index = 0;index < inf;index++) {

        passageiro = this.getPassageiro(passageiros_carrinho,"inf");

        if(passageiro == null){

          passageiros.push({
            nome: "",
            sobrenome: "",
            telefone: "",
            documento: "",
            tipo: "inf",
            titular: false 
          });

        }else{
          passageiros.push(passageiro);
          this.ids.push(passageiro.id); 
        }
        
      }

      
      this.programacao.servicos[index].passageiros = passageiros;   
      this.passageiros.push({
        item: {
          nome: this.programacao.servicos[index].nome, 
          id: this.programacao.servicos[index].id 
        },
        passageiros: passageiros   
      });

      this.setPassageiroTitular(); 

    }

  }
  /**
   * 
   * Seta o passageiro titular
   * 
   */
  setPassageiroTitular(){

    let ok = false;

    for (let index = 0; index < this.passageiros.length; index++) {
      
      if(!ok){

        for (let i = 0;i < this.passageiros[index].passageiros.length;i++) {
          
          if(this.passageiros[index].passageiros[i].tipo == "adt" || this.passageiros[index].passageiros[i].tipo == "snr"){
            this.passageiros[index].passageiros[i].titular = true;
            ok = true; 
          }
          if(ok){
            break; 
          }
          
        }     

      }else{
        break;
      }

      
    }


  }
  /**
   * 
   * Set titular
   * 
   */
  setTitular(passageiro){

    for(let index = 0; index < this.passageiros.length; index++) {
      for(let i = 0;i < this.passageiros[index].passageiros.length;i++) {
        this.passageiros[index].passageiros[i].titular = false;
      }
    }

    passageiro.titular = true;    

  }
  /**
   * 
   * Atualizar
   * 
   */
  atualizar(){

    try{

      this.loader = true;  
    
      this.api.manage().updatePassageiros({
        id: this.programacao.id, 
        servicos: this.passageiros 
      }).subscribe(response => {

        this.loader = false; 

        if(response.status == 1){

          this.data.updateStatusProgramacao.emit(this.programacao);
          this.app.info("Os dados foram atualizados com sucesso.","success");

        }else if(response.status == 422){

          let errors = this.app.formartErrorsValidationPassageiros(response.data);  

          this.app.info(errors,"danger");    

        }else{

          this.app.info("Houve um erro. Tente novamente ou entre em contato com a nossa equipe.","danger");

        }

      },(response) => {

        this.loader = false;
        let error   = this.app.formatError(response);
        this.app.info(error.message,"danger"); 

      });

    }catch(e){

      this.loader = false;
      this.app.info("Houve um erro: "+e.message,"danger");

    }

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(): void {
    this.setProgramacao();
    this.setCamposPassageiros();
  }

}
