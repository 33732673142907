<div class="acomodacao-caracteristica-lista">
      <div class="row">
            <div class="col-md-3" *ngFor="let d of data">
                  <div class="item">
                        <img [src]='d.icone' [alt]='d.titulo' />
                        <span>{{d.titulo}}</span>
                  </div>      
            </div>      
      </div>      
</div>      
