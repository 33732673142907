import { Component, ElementRef, EventEmitter, NgZone, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { FbService } from 'src/app/services/fb.service';
import { GtagService } from 'src/app/services/gtag.service';
import { ModuloService } from 'src/app/services/modulo.service';

declare var $:any;

@Component({
  selector: 'destino-page',
  templateUrl: './destino-page.component.html',
  styleUrls: ['./destino-page.component.scss']
})
export class DestinoPage implements OnInit {

  public loader       = false;
  private apelido     = "destino";
  public response:any = {
    data: "",
    status: null
  };
  public page     = null;
  public fotos    = []; 
  public unidades = []; 
  @ViewChild("messageContato") messageContato: ElementRef;
  @ViewChild("telefoneNumero") telefoneNumero: ElementRef;
  @ViewChild("dataInicio") dataInicio: ElementRef;
  @ViewChild("dataFim") dataFim: ElementRef;
  public dataItem:any = {};
  public message = {
    data: "",
    class: "",
    show: false
  };
  public foto = {
    imagem: "/images/sem-foto/default.jpg",
    titulo: "Sobre o Destino"
  }
  public openGaleria = new EventEmitter();

  constructor(
    private app: AppService,
    private api: ApiService,
    private modulo: ModuloService,
    private zone: NgZone,
    private gtag: GtagService,
    private fb: FbService
  ) { 
   
  }
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;

      this.api.conteudo().page(this.apelido).subscribe(response =>{
      
        this.loader = false;  
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos     = response.data.page.fotos;
            
            this.setFoto();

            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
        
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /**
   * 
   * Set foto
   * 
   */
  setFoto(){

    let foto = null;

    for(let i=0;i < this.fotos.length;i++){

      if(this.fotos[i].capa){

        this.foto = this.fotos[i];
        break;

      }

    }

    if(foto != null){
      this.foto = foto;
    }

  }
  /**
   * 
   * Open galeria
   * 
   */
  _openGaleria(){

    this.openGaleria.emit();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop(); 
    this.getData();
  }
   /**
   * 
   * Iniciliza as Funções depois que a view carregou
   * 
   */
  ngAfterViewInit():void{
    
  }
  ngOnDestroy(): void {
  }

}
