<div class="blog-post-item-two">
      <div class="capa" *ngIf="data.imagem != null">
            <img [src]="data.imagem" [alt]="data.titulo" class="img-fluid" />
      </div> 
      <div class="capa" *ngIf="data.imagem == null">
            <img src="/images/sem-foto/default.jpg" [alt]="data.titulo" class="img-fluid" />
      </div>   
      <div class="content">
            <h4>{{data.titulo}}</h4>
            <div class="linha"></div> 
            <article [innerHtml]="data.min_descricao | safeHtml"></article>
            <div class="content-link">
                  <a routerLink="/blog/{{data.apelido_categoria}}/{{data.apelido}}" [title]="data.titulo" class="link">
                        <span>Ler mais</span>
                  </a>    
            </div>   
      </div>      
</div>  