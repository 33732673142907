import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'partial-links-externos',
  templateUrl: './links-externos.component.html',
  styleUrls: ['./links-externos.component.scss']
})
export class LinksExternosComponent implements OnInit {

  constructor() { }

  ngOnInit():void{}

}
