<div class="modulo modulo-unidades">
  <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
    <h3>{{data.titulo}}</h3>
  </div>  
  <div class="modulo-content">
    <div class="row">
      <div class="col-md-4" *ngFor="let unidade of data.unidades;let i=index;"> 
        <div class="unidade"> 
          <h4>{{unidade.nome}}</h4>
          <p>{{unidade.endereco}}<span *ngIf="unidade.numero !== '' && unidade.numero !== null">, {{unidade.numero}}</span> | {{unidade.bairro}}</p>
          <p *ngIf="unidade.inserir_telefone">Telefone: <a href="tel:{{unidade.telefone}}" [title]="unidade.telefone">{{unidade.telefone}}</a></p>
          <p *ngIf="unidade.inserir_email">Email: <a href="mailto:{{unidade.email}}" [title]="unidade.email">{{unidade.email}}</a></p>
          <p *ngIf="unidade.inserir_horario_funcionamento">Horário: {{unidade.horario_funcionamento}}</p>
          <p *ngIf="unidade.inserir_horario_funcionamento_2">{{unidade.horario_funcionamento_2}}</p>
        </div>
      </div>
    </div>  
  </div>  
</div>
