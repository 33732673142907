import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-destino',
  templateUrl: './destino.component.html',
  styleUrls: ['./destino.component.scss']
})
export class DestinoComponent implements OnInit {

  @Input("data") data;  
  @Input("template") template       = null; 
  @Input("classCustom") classCustom = ""; 
  
  constructor(){} 

  /**
   * 
   * Init data item
   * 
   */
  ngOnInit():void{}

}
