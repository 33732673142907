<div class="dica-item">
    <a [href]="data.link" target="_blank">
        <div class="capa">
            <a itemprop="url" title="Saiba mais sobre: {{data.titulo}}">
            <img [src]="data.imagem" itemprop="image" [alt]="data.titulo" class="img-fluid" />
            </a> 
        </div>
        <div class="titulo">
            <h4>{{data.titulo}}</h4>
        </div>
    </a>    
</div>    
