<div class="dm-modal">
      <div class="dm-modal-title">
            <span>Fale Conosco</span>
            <div class="close" (click)="_close()">
                  <i class="fas fa-times"></i>
            </div>    
      </div> 
      <div class="dm-modal-content">
            <form class="form-custom" (submit)="send()">
                  <div class="row">
                        <div class="col-md-12">
                              <label>Informe seu Nome:</label>
                              <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
                        </div>      
                  </div> 
                  <div class="row">
                        <div class="col-md-6">
                              <label>Informe seu E-mail:</label>
                              <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="contato@email.com" />
                        </div>  
                        <div class="col-md-6">
                              <label>Informe seu Celular:</label>
                              <input type="text" [(ngModel)]="dataItem.telefone" placeholder="(99) 99999-9999" name="telefone" mask="(99) 99999-9999" />
                        </div>     
                  </div>
                  <div class="row">
                        <div class="col-md-12">
                              <label>Assunto:</label>
                              <input type="text" [(ngModel)]="dataItem.assunto" name="assunto" />
                        </div>       
                  </div> 
                  <div class="row">
                        <div class="col-md-12">
                              <label>Escreva uma mensagem:</label>
                              <textarea type="text" [(ngModel)]="dataItem.mensagem" name="mensagem" rows="5"></textarea>
                        </div> 
                  </div> 
                  <div class="row">
                        <div class="col-md-12">
                              <button type="submit">
                                    <span>Enviar</span>
                              </button>      
                        </div>      
                  </div>      
            </form>
      </div>
</div>
<loader *ngIf="loader"></loader>