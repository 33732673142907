import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'acomodacao-caracteristica-lista',
  templateUrl: './acomodacao-caracteristica-lista.component.html',
  styleUrls: ['./acomodacao-caracteristica-lista.component.scss']
})
export class AcomodacaoCaracteristicaListaComponent implements OnInit {

  @Input("data") data = [];

  constructor(){ }

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
