<div class="banner-links">
    <div class="banners">  
        <div class="item" *ngFor="let b of data.banners">
            <figure *ngIf="!b.link_ativo"> 
                <img [lazyLoad]="b.imagem" defaultImage="/images/banner/sem-foto.jpg" [alt]="b.alt" class="img-fluid" />
            </figure>  
            <a [href]="b.link" target="_blank" *ngIf="b.link_ativo">
                <figure> 
                    <img [lazyLoad]="b.imagem" defaultImage="/images/banner/sem-foto.jpg" [alt]="b.alt" class="img-fluid" />
                </figure> 
            </a>   
        </div>    
    </div>    
</div>    
