import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'formatDateText'
})
export class FormatDateTextPipe implements PipeTransform {

  constructor(private date: DateService){}

  transform(value:any, ...args:[]):any {
    
    let data     = value;
    let mes      = this.date.getMonth(data);
    let ano      = this.date.getYear(data);
    let dia      = this.date.getDay(data);
    
    
    return dia+" de "+this.date.getMonthString(data)+" de "+ano;


  }

}
