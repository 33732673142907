<div class="modulo modulo-dicas">
    <div class="container"> 
        <div class="btn-hashtag">
            #superdicas
        </div> 
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
                    <h2 [innerHtml]="data.titulo | safeHtml"></h2>
                    <h3 [innerHtml]="data.subtitulo | safeHtml">{{data.subtitulo}}</h3>
                </div> 
            </div>
            <div class="col-lg-9 col-md-12">   
                <div class="modulo-content">
                    <div class="slider-content">
                        <div class="slider-itens" #slider> 
                            <div class="slider-item" *ngFor="let d of data.dicas">
                                <dica-item [data]="d"></dica-item> 
                            </div>    
                        </div>
                        <div class="swiper-button-prev sw-btn" (click)="prev()">
                            <img src="/images/icons/arrow-left.svg" alt="Arrow" />
                        </div>
                        <div class="swiper-button-next sw-btn" (click)="next()">
                            <img src="/images/icons/arrow-right.svg" alt="Arrow" />
                        </div>
                    </div>          
                </div>  
            </div>
        </div>        
    </div>      
</div>    
