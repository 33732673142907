<div class="page page-trabalhe-conosco" itemtype="https://schema.org/AboutPage">
  <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0"> 
    <div class="container">
      <breadcrumbs [itens]="itensBreadcrumbs"></breadcrumbs>
    </div>  
  </div>   
  <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
    <div class="page-title" *ngIf="page.mostrar_titulo">
      <div class="container">
        <h2 itemprop="name">{{page.titulo}}</h2>
        <span *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</span>
      </div>  
    </div>  
    <div class="page-content">
      <section class="section descricao" *ngIf="page.mostrar_texto">
        <div class="container">
          <article itemprop="text" [innerHTML]="page.texto | safeHtml"></article>
        </div>  
      </section> 
      <section class="section section-form">
        <div class="container">
          <form class="form form-custom">
            <div class="form-group">
              <label>
                <i class="fal fa-user"></i>
              </label> 
              <input type="text" [(ngModel)]="dataItem.nome" placeholder="Digite seu nome*" name="nome" /> 
            </div> 
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    <i class="fal fa-envelope"></i>
                  </label> 
                  <input type="text" [(ngModel)]="dataItem.email" placeholder="Digite seu e-mail*" name="email" /> 
                </div>
              </div> 
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    <i class="fal fa-phone fa-flip-horizontal"></i>
                  </label> 
                  <input type="text" [(ngModel)]="dataItem.telefone" placeholder="Digite seu telefone*" name="telefone" phoneMask /> 
                </div>
              </div>  
            </div>
            <div class="form-group">
              <span class="text-file">Selecione seu currículo:</span>
              <label>
                <i class="fal fa-file"></i>
              </label> 
              <input type="file" [(ngModel)]="dataItem.curriculo" name="file"  #file /> 
            </div>
            <div class="form-group">
              <textarea type="text" [(ngModel)]="dataItem.mensagem" placeholder="Digite sua mensagem*" name="mensagem" rows="6"></textarea> 
            </div>  
            <div class="form-group" [hidden]="subscribeMessage == null">
              <div class="subscribe-message" [innerHtml]="subscribeMessage"></div>
            </div>  
            <div class="form-group" *ngIf="messageSend" class="message-send">
              <span>Mensagem enviada com sucesso!</span>
            </div>  
            <div class="form-group">
              <button type="submit" class="btn-one" (click)="enviar()">
                <span *ngIf="!loaderForm">ENVIAR</span>
                <span *ngIf="loaderForm">ENVIANDO...</span>
              </button>  
            </div>
          </form>  
        </div>  
      </section>  
    </div>  
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container"> 
      <page-error [data]="response.data" (reload)="getData()"></page-error> 
    </div>  
  </div>  
</div>  
<loader message="Carregando Conteúdo" [hidden]="!loader"></loader>  
    