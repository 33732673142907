<div class="links-externos">
    <ul>
        <li class="evento">
            <div class="content">
                <span class="titulo">FAÇA SEU EVENTO CONOSCO!</span>
                <span class="subtitulo">Congressos | Feiras | Corporativos</span>
                <a href="https://brockereventos.com.br/" target="_blank" title="FAÇA SEU EVENTO CONOSOCO. Clique aqui!">
                    CONFIRA!
                </a>    
            </div>
        </li> 
        <li class="destino">
            <div class="content"> 
                <span class="titulo">ESCOLHA O SEU DESTINO E</span>
                <span class="subtitulo">embarque</span> 
                <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/aviao.svg" alt="Avião - Brocker Turismo" />
                <a href="#" target="_blank" title="ESCOLHA SEU DESTINO. Clique aqui!">
                    CONFIRA! 
                </a> 
            </div>    
        </li> 
        <li class="portal">
            <div class="content">
                <span class="titulo">SEJA NOSSO AFILIADO!</span>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeQhiLRtnZYMePKHYP3uUAtNzFtKZFuG3fUueynxnrTu42eKw/viewform?usp=sf_link" target="_blank" title="SEJA NOSSO  AFILIADO. Clique aqui!">
                    ACESSE O PORTAL 
                </a> 
            </div>    
        </li>    
    </ul>    
</div>    
