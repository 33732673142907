import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss']
})
export class BlogPostsComponent implements OnInit {

  @Input("data") data:any   = {};
  @Input("apelido") apelido = null;
  public categoria          = null;
  public posts              = null;
  public loader             = false;
  public titulo = null;

  constructor(
    public app: AppService,
    public api: ApiService
  ){ 

  }
  /***
   * 
   * get posts
   * 
   */
  getData(){

    try{

      this.loader = false;

    }catch(e){

      this.loader = false;

    }

  }

  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.posts     = this.data.posts;
    this.categoria = this.data.categoria;
  }

}
