<div class="galeria-light" [ngSwitch]="template">
  <div class="galeria-light-content default" *ngSwitchCase="null">
    <div class="row" id="animated-thumbnials" #galeria>
      <a *ngFor="let foto of fotos" [href]="foto.imagem" [title]="foto.titulo" class="{{classCustom}} col-md-3">
        <img [defaultImage]="defaultImage" [errorImage]="defaultImage" [lazyLoad]="foto.imagem" [title]="foto.alt" class="img-fluid" />
      </a>
    </div>
  </div> 
  <div class="galeria-light-content template-cidade" *ngSwitchCase="'page-cidade'">
    <div id="animated-thumbnials" #galeria>  
      <a *ngFor="let foto of fotos"  [href]="foto.imagem" [title]="foto.titulo" class="{{classCustom}}">
        <img [defaultImage]="defaultImage" [errorImage]="defaultImage" [lazyLoad]="foto.imagem" [title]="foto.alt" class="img-fluid" />
      </a> 
    </div> 
  </div> 
  <div class="galeria-light-content default-destino" *ngSwitchCase="'modulo-destino'">
    <div class="row" id="animated-thumbnials" #galeria>
      <a *ngFor="let foto of fotos" [href]="foto.imagem" [title]="foto.titulo" class="{{classCustom}} col-md-4">
        <img [defaultImage]="defaultImage" [errorImage]="defaultImage" [lazyLoad]="foto.imagem" [title]="foto.alt" class="img-fluid" />
      </a>
    </div>
  </div>  
</div>
