import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'blog-post-item-two',
  templateUrl: './blog-post-item-two.component.html',
  styleUrls: ['./blog-post-item-two.component.scss']
})
export class BlogPostItemTwoComponent implements OnInit {

  @Input("data") data = null;

  constructor(){} 

  /***
   * 
   * Init
   * 
   */
  ngOnInit(): void {}

}
