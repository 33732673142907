<div [ngSwitch]="template">
  <div class="modulo modulo-newsletter" *ngSwitchCase="null">
    <div class="modulo-content">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="titulo"> 
              <h3>{{data.titulo}}</h3>
              <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
            </div>
          </div>
          <div class="col-md-9">
            <div class="content">
              <form class="form" (submit)="send()" autocomplete="off" autocapitalize="off">
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Seu nome..." />
                </div>
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Seu e-mail" />
                </div> 
                <div class="form-group"> 
                  <input type="tel" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu WhatsApp" mask="(99) 99999-9999" />
                </div>
                <div class="form-group form-button"> 
                  <button type="button" (click)="send()" [disabled]="loader">
                    <span>Enviar</span>
                  </button>
                </div>    
              </form>
            </div>
          </div>  
        </div>  
      </div>
    </div>
  </div>
</div> 
<loader *ngIf="loader"></loader> 
