import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'blog-item-page',
  templateUrl: './blog-item-page.component.html',
  styleUrls: ['./blog-item-page.component.scss']
})
export class BlogItemPage implements OnInit {

  public apelido                 = null;
  public apelidoCategoria        = null;
  public loader = false;
  public mySubscription:Subscription;
  public destroyed               = new Subject<any>();
  public destroy                 = false;
  public response = {
    data: null,
    status: null
  }
  public post:any = null;
  public itensBreadcrumbs = [];
  public chamadaTexto     = "Veja algumas fotos";
  
  constructor(
    public api: ApiService,
    public app: AppService,
    private router: Router,
    private route: ActivatedRoute
  ){

    this.apelido          = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
    this.apelidoCategoria = typeof(this.route.params["_value"].apelido_categoria) != "undefined" ? this.route.params["_value"].apelido_categoria : null;
  
  }  
  /**
   * 
   * Get data
   * 
   */
  getData(){

    try{

      this.loader = true;  

      this.api.blog().post(this.apelido).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data.post === null || response.data.post === "null"){
            this.router.navigateByUrl("/");
          }else{
            this.post = response.data;
            this.setItensBreadcrumbs();
            this.setChamadaTexto();
            this.app.setMetaDados(this.post.post.meta_title,this.post.post.meta_description);

            this.response.status = 1;
          }

        }else{

          let error   = this.api.formatError(response);
          this.response = {
            data: error.message,
            status: 500
          }

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;
      this.response = {
        data: e.message,
        status: 500
      }

    }

  }
  /***
   * 
   * Set breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Blog",
      routerLink: "/blog"
    });
    this.itensBreadcrumbs.push({
      text: this.post.categoria.titulo,
      routerLink: "/blog/"+this.post.categoria.apelido
    });
    this.itensBreadcrumbs.push({
      text: this.post.post.titulo,
      routerLink: "/blog/"+this.post.categoria.apelido+"/"+this.post.post.apelido
    });

  }
  /**
   * 
   * Set chamada texto
   * 
   */
  setChamadaTexto(){

    if(this.post.post.chamada_texto !== null  && this.post.post.chamada_texto !== ""){

      this.chamadaTexto = this.post.post.chamada_texto;

    }

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{

    this.app.toTop();
    this.getData(); 

    this.mySubscription = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {

      if(!this.destroy){
        
        let apelido           = this.route.params["_value"].apelido;
        let apelidoCategoria = this.route.params["_value"].apelido_categoria;
        if(typeof(apelido) != "undefined"){
          this.apelido          = apelido; 
          this.apelidoCategoria = apelidoCategoria; 
          this.getData();
        }  
      }

    });

  }

}
