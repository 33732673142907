<div class="cupom-desconto">
    <span class="total-desconto" *ngIf="total > 0">
        Total do Desconto: {{total | currency: ' R$ '}}
    </span>
    <form class="form form-custom" (submit)="validar()">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <input type="text" [(ngModel)]="dataItem.codigo" name="codigo" uppercase />
                </div> 
            </div>
            <div class="col-md-4">
                <button type="submit" class="btn-one" [disabled]="dataItem.codigo === '' || dataItem.codigo === null">
                    OK
                </button>
            </div>
        </div>        
    </form> 
</div>      
<loader *ngIf="loader"></loader>  