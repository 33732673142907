<div class="page page-sobre" itemtype="https://schema.org/AboutPage">
  <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0"> 
    <breadcrumbs [itens]="itensBreadcrumbs"></breadcrumbs>
  </div>  
  <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
    <div class="page-title" *ngIf="page.mostrar_titulo">
      <div class="container">
        <h1 itemprop="name">{{page.titulo}}</h1>
        <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
      </div>  
    </div>  
    <div class="page-content">
      <section class="section section-descricao">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <article [innerHtml]="page.texto | safeHtml"></article>
                </div>  
                <div class="col-lg-6 col-md-12">
                    <div class="box-foto">
                        <div class="foto" (click)="_openGaleria()">
                            <img [src]="foto.imagem" [alt]="foto.titulo" />
                        </div>
                        <div class="bg"></div>
                    </div>    
                </div>    
            </div>  
        </div> 
      </section>
      <section class="section section-fotos">
          <galeria-light [open]="openGaleria" [fotos]="fotos"></galeria-light>   
      </section> 
      <section class="section section-mapa" *ngIf="page.ativar_mapa">
        <mapa-google [latitude]="page.latitude" [longitude]="page.longitude"></mapa-google>
      </section>  
    </div> 
  </div> 
  <div *ngIf="response.status == 500"> 
    <div class="container"> 
      <page-error [data]="response.data" (reload)="getData()"></page-error>
    </div>  
  </div>  
</div>  
<loader message="Carregando Conteúdo" [hidden]="!loader"></loader> 
    