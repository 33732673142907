<div class="modulo modulo-fale-conosco" [ngSwitch]="template">
  <div *ngSwitchCase="null" class="default">
    <div class="container">
      <div class="row">
        <div class="col-md-3">  
          <div class="modulo-titulo">
            <div class="content">
              <h3>{{data.titulo}}</h3> 
              <h4 [innerHtml]="data.subtitulo"></h4>
            </div>  
          </div>  
        </div>
        <div class="col-md-9">
          <div class="modulo-content">
            <ul>
              <li class="li-whatsapp" (click)="openWhatsApp()">
                <span class="icon">
                  <i class="fab fa-whatsapp"></i>
                </span>
                <span class="title">WhatsApp</span>
                <span class="text">Fale com um dos nossos atendentes agora :)</span>  
              </li>
              <li class="phone">
                <span class="icon">
                  <i class="fas fa-phone"></i> 
                </span>
                <span class="title">Telefone</span>
                <span class="text">Fale com nós por telefone</span> 
                <span class="value">
                  <a href="tel:{{data.params.telefone}}" title="Telefone">
                    {{data.params.telefone}}
                  </a>
                </span> 
              </li>  
              <li class="email">
                <span class="icon">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="title">E-mail</span>
                <span class="text">Envie uma mensgam no</span> 
                <span class="value">
                  <a href="mailto:{{data.params.email}}" title="E-mail">{{data.params.email}}</a>
                </span> 
              </li>  
              <li class="li-contato">
                <span class="title">Envie uma mensagem</span>
                <span class="text">Clique no botão abaixo para enviar uma mensagem</span>
                <button type="button" (click)="open()">  
                  <span>Clique aqui</span>
                </button>
              </li>  
            </ul>  
          </div>
        </div>
      </div> 
    </div>
  </div>
  <div *ngSwitchCase="'footer'" class="default-footer">
    <h3 class="modulo-title">Precisa de ajuda?</h3>
    <h4>Nos envie a sua dúvida</h4>
    <div class="content">
      <form class="form" (submit)="send()" autocomplete="off">
        <div class="row">
          <div class="col-md-6 col-one">
            <input type="text" [(ngModel)]="dataItem.nome" placeholder="Informe seu nome*" name="nome" />
          </div>
          <div class="col-md-6 col-two">
            <input type="text" [(ngModel)]="dataItem.email" placeholder="Informe seu e-mail*" name="email" />
          </div> 
        </div>
        <div class="row">
          <div class="col-md-12">
            <textarea type="text" [(ngModel)]="dataItem.mensagem" placeholder="Qual a sua dúvida?" name="mensagem"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button type="submit">
              <span>Enviar</span>
            </button>  
          </div>  
        </div>  
      </form>    
    </div>
  </div> 
</div>
<loader *ngIf="loader"></loader>
