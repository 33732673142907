<div class="modulo">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <div class="content">
                        <a routerLink="/blog" title="Blog">
                              <h2>{{data.titulo}}</h2>
                              <div class="detalhe"><span></span><span></span><span></span></div>
                              <h3>{{data.subtitulo}}</h3>
                        </a>
                  </div>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container"> 
                  <div class="produtos slider-itens" #slider>   
                        <div class="slider-item item-servicos" *ngFor="let item of data.posts"> 
                          <blog-post-item [data]="item"></blog-post-item>    
                        </div>    
                  </div>
                  <div class="swiper-button-prev sw-btn" (click)="prev()">
                        <img src="/images/icons/arrow-left.svg" alt="Arrow" width="25px" />
                  </div>
                  <div class="swiper-button-next sw-btn" (click)="next()">
                        <img src="/images/icons/arrow-right.svg" alt="Arrow" width="25px" />
                  </div>       
            </div>
      </div>      
</div>     
