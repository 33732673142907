import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'combo-horarios',
  templateUrl: './combo-horarios.component.html',
  styleUrls: ['./combo-horarios.component.scss']
})
export class ComboHorariosComponent implements OnInit {

  @Input("label") label         = "Horário:"; 
  @Input("value") value         = null;
  @Input("data") data           = [];
  @Output("changeValue") change = new EventEmitter();
  public parcelas = [];
  
  constructor(){ }
  
  /**
   * 
   *  Atualiza o valor selecionado
   * 
   * @param value
   * 
   */
  clickValue(value){

    this.change.emit(value);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    
  }

}
