import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'fale-conosco-modal',
  templateUrl: './fale-conosco-modal.component.html',
  styleUrls: ['./fale-conosco-modal.component.scss']
})
export class FaleConoscoModal implements OnInit {

  public dataItem:any = {};
  public loader = false;

  constructor(
    public dialog: MatDialogRef<FaleConoscoModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    public app: AppService,
    public api: ApiService,
    public validator: ValidatorService
  ){ }

  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe o Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail informado é inválido.");
      this.validator.isEmpty(this.dataItem.assunto,"assunto","- Informe o Assunto.");
      this.validator.isEmpty(this.dataItem.mensagem,"mensagem","- Escreva a Mensagem.");

      if(!this.validator.passes()){

        this.app.info(this.validator.getMessagesHtml());
        return false;
  
      }

      this.loader = true;

      this.api.contato().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Recebemos a sua mensagem. Em breve entraremos em contato.");
          this.initDataItem();  

        }else{

          let error   = this.api.formatError(response);
          this.app.info(error.message); 

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.app.info(error.message); 

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close();

  }
  /***
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem  = {
      nome: "",
      email: "",
      apelido: "fale-conosco",
      tipo: 2,
      telefone: "",
      assunto: "",
      mensagem: ""
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}
