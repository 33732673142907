import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FaleConoscoModal } from 'src/app/components/modais/fale-conosco-modal/fale-conosco-modal.component';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { GtagService } from 'src/app/services/gtag.service';
import { ValidatorService } from 'src/app/services/validator.service';

declare var window:any;

@Component({
  selector: 'modulo-fale-conosco', 
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.scss']
})
export class FaleConoscoComponent implements OnInit {

  @Input("data") data:any           = {};      
  @Input("template") template       = "one";
  @Input("classCustom") classCustom = "";
  public dataItem:any               = {};
  public loader                     = false;
  public subscribeMessage           = "";
  public showArea                   = true;
  @ViewChild("textarea") textarea:ElementRef;
  public numero  = null;
  public prefixo = "web";

  constructor(
    private api: ApiService,
    private app: AppService,
    private gtag: GtagService,
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    private validator: ValidatorService
  ){ }

  /**
   * 
   * Send data
   * 
   */
  send(){

    try{ 

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe o seu Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o seu E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail informado é inválido.");
      this.validator.isEmpty(this.dataItem.mensagem,"mensagem","- Escreva a sua dúvida.");

      if(!this.validator.passes()){

        this.app.info(this.validator.getMessagesHtml());  
        return false;

      }

      this.loader           = true;
      this.subscribeMessage = "";
      this.gtag.submitContato();

      this.api.contato().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
        
          this.initDataItem();
          this.app.info("Recebemos a sua dúvida. Em breve retornaremos.");
        
        }else{
          
          let error = this.api.formatError(response);
          this.app.info(error.message);
            
        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);

        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;
      

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      mensagem: "",
      tipo: 4,
      apelido: "ajuda"  
    }

  }
  setShowArea(){

    this.showArea = false;

    setTimeout(() => {
      this.textarea.nativeElement.focus();
    },200)
    

  }
  /**
   * 
   * Open WhatsApp
   * 
   */
  openWhatsApp(){

    window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank");

  }
  /**
   * 
   * Open
   * 
   */
  open(){

    this.dialog.open(FaleConoscoModal,{
      width: "800px"
    });

  }
  /**
   * 
   * Seta o número
   * 
   */
  setNumero(){
    
    let telefone = this.data.params.whatsapp.replace("(","").replace(")","").replace("-","").replace(" ","");
    this.numero = "55"+telefone;


  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){  
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";
    this.initDataItem();
    this.setNumero();
  }

}
