<footer>
    <section class="footer-redes-sociais">
        <div class="linha"></div>
        <div class="container">  
            <modulo posicao="rede-social"></modulo>     
        </div> 
    </section> 
    <section class="footer-fale-conosco"> 
        <modulo posicao="fale-conosco"></modulo>
    </section>    
    <section class="footer-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-4">   
                    <partial-menu-links-uteis></partial-menu-links-uteis>
                </div> 
                <div class="col-lg-2 col-md-4 col-left">   
                    <modulo posicao="endereco"></modulo>
                </div>  
                <div class="col-lg-2 col-md-4 col-left">
                    <modulo posicao="contato" template="footer"></modulo>
                </div> 
                <div class="col-lg-4 col-md-8">
                    <modulo posicao="fale-conosco" template="footer"></modulo>
                </div>    
                <div class="col-lg-1 col-md-4">
                    <modulo class="bloco bloco-logo" template="footer" posicao="logo" classCustom="logo-footer"></modulo> 
                </div>    
            </div>    
        </div> 
    </section> 
    <div class="copyright">
        <div class="container">
            <div class="row">  
                <div class="col-12">
                    <div class="copy-text"> 
                        Todos os direitos reservados - Pousada São José - 2021 | Desenvolvido por 
                        <a href="https://www.digitalmovement.com.br" target="_blank">
                            <strong>Digital Movement</strong>
                            <img src="/assets/digital-movement/logo-digital-movement-2.svg" alt="Digital Movement - Soluções em Tecnologia" />
                        </a>. 
                    </div> 
                </div> 
            </div>       
        </div>    
    </div>    
</footer>    