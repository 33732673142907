<div class="acomodacao-item-two">
      <div class="row">
            <div class="col-md-6">
                  <div class="box-content">
                        <div class="box">
                              <div class="body">
                                    <h3>{{data.nome}}</h3>
                                    <article [innerHtml]="data.descricao | safeHtml"></article>
                              </div> 
                              <div class="content-link">
                                    <a routerLink="/acomodacoes/{{data.apelido}}" [title]="data.nome" class="btn-detalhe">
                                          <span>Detalhes e Reserva</span>
                                    </a>
                              </div>     
                        </div>   
                        <div class="bg"></div>   
                  </div>
            </div>
            <div class="col-md-6">
                  <galeria-carrossel-images [data]="data.fotos" template="two"></galeria-carrossel-images>
            </div>      
      </div>      
</div>
