import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GtagService } from 'src/app/services/gtag.service';

declare var window:any;
declare var $:any;

@Component({
  selector: 'modulo-whatsapp', 
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit,AfterViewInit {

  @Input("data") data:any           = null; 
  @Input("template") template       = null; 
  @Input("classCustom") classCustom = "";
  public prefixo                    = "web"; 
  public numero                     = "";
  public text                       = "Clique aqui e tire suas dúvidas via WhatsApp"; 
  @ViewChild("fixo") fixo:ElementRef = null; 

  constructor(
    private deviceService: DeviceDetectorService,
    private gtag: GtagService
  ){} 
  
   /**
   * 
   * Open
   * 
   */
  open(){

    if(this.text !== "" && this.text !== null){
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero+"&text="+this.text,"__blank");
    }else{
      window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank");
    }  
    this.gtag.clickWhatsApp();

  }
  /**
   * 
   * Seta o número
   * 
   */
  setNumero(){
    
    let telefone = this.data.params.telefone.replace("(","").replace(")","").replace("-","").replace(" ","");
    this.numero = "55"+telefone;


  }
  /**
   * 
   * Seta a mensage
   * 
   */
  setMessage(){

    if(typeof(this.data.params.texto) != "undefined"){
      this.text = this.data.params.texto;
    }

  }
  /***
   * 
   * Set Scrol
   * 
   */
  setScroll(){
    
    let fixo = this.fixo.nativeElement; 
  
    $(window).scroll(function(){
      
      var fromTop = $(this).scrollTop();
            
      if(fromTop > 500){  
        fixo.classList.add("single"); 
      }else{
        fixo.classList.remove("single");    
      } 

    }); 

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
   
    
    this.setNumero();
    this.setMessage(); 
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";

    
  }
  /**
   * 
   * O loaded
   * 
   */
  ngAfterViewInit(): void {
    this.setScroll();
  }

}
