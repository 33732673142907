import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'blog-post-item',
  templateUrl: './blog-post-item.component.html',
  styleUrls: ['./blog-post-item.component.scss']
})
export class BlogPostItemComponent implements OnInit {

  @Input("data") data = null;

  constructor(){} 

  /***
   * 
   * Init
   * 
   */
  ngOnInit(): void {}

}
