<div class="modulo default" *ngIf="template == null">
  <a routerLink="/">
    <figure class="logo {{classCustom}}"> 
      <img [src]="logo" alt="Logo" />
    </figure>
  </a> 
</div>
<div class="modulo" *ngIf="template == 'footer'">
  <span class="texto">{{data.params.texto}}</span>
  <a routerLink="/">
    <figure class="logo {{classCustom}}"> 
      <img [src]="logo" alt="Logo" />
    </figure>
  </a> 
</div>
  