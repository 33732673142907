<div class="mat-modal-info">
  <div class="mat-modal-info-title">
    <h4>Atenção</h4>
  </div>  
  <div class="mat-modal-info-content">
    <article class="message" [innerHtml]="data.message">
    </article>    
  </div>
  <div class="mat-modal-info-footer">
    <button type="modal" class="btn-four" mat-raised-button (click)="close()">
      OK
    </button> 
  </div>   
</div>
  