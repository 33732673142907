<div class="dm-modal">
    <div class="dm-modal-header">
        <span>
            <mat-icon>person</mat-icon>
            Atualize os passageiros
        </span> 
        <span class="dm-close" (click)="dialog.close()">
            <mat-icon>cancel</mat-icon>
        </span>    
    </div>    
    <div class="dm-modal-content">
        <div class="servicos">
            <div class="servico" *ngFor="let servico of passageiros">
                <div class="nome">
                    <span>{{servico.item.nome}}</span>
                </div>
                <div class="passageiros form-custom">
                    <div class="passageiro-item" *ngFor="let p of servico.passageiros;let y=index">
                        <span>Passageiro {{y+1}} - {{p.tipo | tipoPassageiroDesc}}</span> 
                        <div class="row">
                            <div class="col-md-6"> 
                                <input type="text" [(ngModel)]="p.nome" name="nome" placeholder="Nome*" name="nome" uppercase /> 
                            </div> 
                            <div class="col-md-6">   
                                <input type="text" [(ngModel)]="p.sobrenome" name="sobrenome" placeholder="Sobrenome*" name="sobrenome" uppercase />
                            </div>    
                        </div> 
                        <div class="row">
                            <div class="col-md-6"> 
                                <input type="text" [(ngModel)]="p.telefone" name="telefone" placeholder="Telefone" name="telefone" mask="(99) 99999-9999" />
                            </div> 
                            <div class="col-md-6">  
                                <input type="text" [(ngModel)]="p.documento" name="documento" placeholder="Documento*" name="documento" />
                            </div>    
                        </div>
                        <div class="row" *ngIf="p.tipo == 'adt' || p.tipo == 'snr'">
                            <div class="col-md-12">
                                <button type="button" class="btn-titular" [ngClass]="{'active': p.titular}" (click)="setTitular(p)">
                                    Titular da Reserva
                                </button>
                            </div>    
                        </div>
                    </div> 
                </div> 
            </div> 
        </div>    
    </div> 
    <div class="dm-modal-footer">
        <button type="button" class="btn-four" (click)="atualizar()"> 
            Atualizar
        </button>    
    </div>   
</div>    
<loader *ngIf="loader"></loader> 