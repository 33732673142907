import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ApiService } from '../../services/api.service';
import { GtagService } from '../../services/gtag.service';
import { FbService } from '../../services/fb.service';

declare var $:any;
declare var window: any;

@Component({
  selector: 'trabalhe-conosco-page',
  templateUrl: './trabalhe-conosco-page.component.html',
  styleUrls: ['./trabalhe-conosco-page.component.scss']
})
export class TrabalheConoscoPage implements OnInit, AfterViewInit, OnDestroy {

  public loaderForm   = false;
  public loader       = false; 
  private apelido     = "trabalhe-conosco";
  public response:any = {
    data: "",
    status: null
  };
  public page    = null;
  public fotos   = []; 
  public params  = {};
  public valores = [];
  public itensBreadcrumbs = [];
  public dataItem:any     = {};
  public subscribeMessage = null;
  @ViewChild("file") file:ElementRef;
  public messageSend     = false;
  public isSendAnalytics = false;

  constructor(
    private app: AppService,
    private api: ApiService,
    private gtag: GtagService, 
    private fb: FbService
  ) { }

  /***
   * 
   * Enviar
   * 
   */
  enviar(){

    try{

      this.gtag.clickTrabalheConosco();
      this.subscribeMessage = null;
      this.loaderForm           = true;

      var formData  = new FormData();
      let curriculo = typeof($(this.file.nativeElement)[0].files[0]) == "undefined" ? "" : $(this.file.nativeElement)[0].files[0];

      formData.append('curriculo',curriculo);
			formData.append('nome',this.dataItem.nome);
			formData.append('email',this.dataItem.email);
			formData.append('telefone',this.dataItem.telefone);
      formData.append('mensagem',this.dataItem.mensagem);

      this.api.contato().trabalhe_conosco(formData).subscribe((response) => {

        this.loaderForm = false;

        if(response.status == 1){

          this.subscribeMessage = null;
          this.messageSend = true;
          this.file.nativeElement.value = "";
          this.app.info("Recebemos o seu currículo. Em breve entraremos em contato.","success");   
          this.initDataItem();

        }else{

          //this.subscribeMessage = response.data;
          this.app.info(response.data,"danger");   
        
        }

      }, (response) => {

        this.loaderForm = false;
        let errors  = this.app.formatError(response);

        this.app.info(errors.message,"danger");  

      });

    }catch(e){

      this.loader = false;
      
    }

  }
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true; 
      
      this.api.conteudo().page(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                   
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = JSON.parse(this.page.params);
            this.fotos     = response.data.page.fotos;
            this.valores   = response.data.page.valores; 
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
            
          }
          this.response = {
            data: "",
            status: 1
          }
          this.isSendAnalytics = false; 
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Trabalhe Conosco"
    });

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      curriculo: "",
      mensagem: ""
    }

  }
  /**
   * 
   * Send analytics
   * 
   */
  sendAnalytics(){

    if(!this.isSendAnalytics){
      this.isSendAnalytics = true;
      setTimeout(() => {
      
        this.gtag.sendPage();
        this.fb.init();
        this.fb.pageView();
        this.fb.lead();

      },100);

    }

  } 
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop();
    this.getData();
    this.setItensBreadcrumbs();
    this.initDataItem();
  }
  ngAfterViewInit(): void {
   
  }
  ngOnDestroy(): void {
    this.isSendAnalytics = false;
  }

}
