<div class="blog-slider">
      <div class="slider-itens" #slider> 
            <div class="slider-item" *ngFor="let d of data">
                <img [src]="d.imagem" [alt]="d.titulo" class="" />
            </div>      
      </div>
      <div class="swiper-button-prev sw-btn" (click)="prev()">
            <img src="/images/icons/slider/prev-02.svg" alt="Arrow" />
      </div>
      <div class="swiper-button-next sw-btn" (click)="next()">
            <img src="/images/icons/slider/next-02.svg" alt="Arrow" />
      </div>    
</div>