<div class="page page-destino">
      <div *ngIf="response.status == 1"> 
        <div class="page-title" *ngIf="page.mostrar_titulo">
          <div class="container">
            <h1>{{page.titulo}}</h1>
            <h2 *ngIf="page.mostrar_subtitulo">
              {{page.subtitulo}}
            </h2>
          </div> 
          <div class="linha"></div> 
        </div> 
        <div class="page-content">
            <section class="section section-descricao">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <article [innerHtml]="page.texto | safeHtml"></article>
                        </div>  
                        <div class="col-md-6">
                            <div class="box-foto">
                                <div class="foto" (click)="_openGaleria()">
                                    <img [lazyLoad]="foto.imagem" errorImage="/images/sem-foto/default.jpg" defaultImage="/images/sem-foto/default.jpg" [alt]="foto.titulo" />
                                </div>
                                <div class="bg"></div>
                            </div>    
                        </div>    
                    </div>  
                </div> 
            </section>
            <section class="section section-fotos">
                <galeria-light [open]="openGaleria" [fotos]="fotos"></galeria-light>   
            </section>
        </div> 
      </div> 
      <div *ngIf="response.status == 500">
        <div class="container">
          <page-error [data="response.data" (reload)="getData()"></page-error>
        </div>  
      </div>  
</div>
<loader [hidden]="!loader"></loader>
    