import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-banner-links',
  templateUrl: './banner-links.component.html',
  styleUrls: ['./banner-links.component.scss']
})
export class BannerLinksComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = {};

  constructor(){ } 

  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{}

}
