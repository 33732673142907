import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class CartService {

  public emitirDeleteItem      = new EventEmitter();
  public emitirUpdateTotalCart = new EventEmitter(); 
  public emitirUpdateDesconto  = new EventEmitter();
  public emitirUpdateCupom     = new EventEmitter();

  constructor(){ }    


}
