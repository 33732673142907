import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePage } from './pages/home-page/home-page.component';
import { SobrePage } from './pages/sobre-page/sobre-page.component';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';    
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';  
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; //@3.4.2
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatModalInfoDialogComponent } from './components/modais/mat-modal-info-dialog/mat-modal-info-dialog.component';
import { ModalConfirmComponent } from './components/modais/modal-confirm/modal-confirm.component'; 
import { IntegerDirective } from './directives/integer.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { ModulosResolver } from './resolvers/modulos.resolver';
import { ModuloService } from './services/modulo.service';  
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ModuloLogo } from './modulos/logo/logo.component';
import { Modulo } from './modulos/modulo/modulo.component';
import { DataService } from './services/data.service';
import { GaleriaLightComponent } from './components/galeria/galeria-light/galeria-light.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { GtagService } from './services/gtag.service';
import { HeaderComponent } from './components/partial/header/header.component';
import { FooterComponent } from './components/partial/footer/footer.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { WhatsappComponent } from './modulos/whatsapp/whatsapp.component';
import { RedeSocialComponent } from './modulos/rede-social/rede-social.component';
import { EnderecoComponent } from './modulos/endereco/endereco.component';
import { UnidadesComponent } from './modulos/unidades/unidades.component';
import { PageErrorComponent } from './components/error/page-error/page-error.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { MenuComponent } from './components/partial/menu/menu.component';
import { BannerSliderSlickComponent } from './modulos/banner-slider-slick/banner-slider-slick.component';
import { MenuFooterComponent } from './components/partial/menu-footer/menu-footer.component';
import { NewsletterComponent } from './modulos/newsletter/newsletter.component';
import { ContatoComponent } from './modulos/contato/contato.component';
import { FaleConoscoComponent } from './modulos/fale-conosco/fale-conosco.component';
import { LinksExternosComponent } from './components/partial/links-externos/links-externos.component';
import { DicasComponent } from './modulos/dicas/dicas.component';
import { DicaItemComponent } from './components/dica/dica-item/dica-item.component';
import { BannerDestaqueComponent } from './modulos/banner-destaque/banner-destaque.component';
import { LinkDestaquesComponent } from './modulos/link-destaques/link-destaques.component';
import { FormatDuracaoPipe } from './pipes/format-duracao.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { FormatValorManagerPipe } from './pipes/format-valor-manager.pipe';
import { ComboManagerHotelComponent } from './components/combos/combo-manager-hotel/combo-manager-hotel.component';
import { ServicoService } from './services/servico.service';
import { StorageService } from './services/storage.service';
import { ValidatorService } from './services/validator.service';
import { CartService } from './services/cart.service';
import { GetTarifaMinimaListaPipe } from './pipes/get-tarifa-minima-lista.pipe';
import { MatSelectSearchComponent } from './components/combos/mat-select-search/mat-select-search.component';
import { MatOptionModule } from '@angular/material/core';
import { TipoServicoPipe } from './pipes/tipo-servico.pipe';
import { FormatDateTextPipe } from './pipes/format-date-text.pipe';
import { DateService } from './services/date.service';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MaskDirective } from './directives/mask.directive';
import { ComboParcelasCartaoComponent } from './components/combos/combo-parcelas-cartao/combo-parcelas-cartao.component';
import { TipoPassageiroDescPipe } from './pipes/tipo-passageiro-desc.pipe';
import { TrabalheConoscoPage } from './pages/trabalhe-conosco-page/trabalhe-conosco-page.component';
import { UsuarioService } from './services/usuario.service';
import { DateFormatTextPipe } from './pipes/date-format-text.pipe'; 
import { ComboAeroportoComponent } from './components/combos/combo-aeroporto/combo-aeroporto.component';
import { ComboCiaAereaComponent } from './components/combos/combo-cia-aerea/combo-cia-aerea.component';
import { PoliticaDeCancelamentoPage } from './pages/politica-de-cancelamento-page/politica-de-cancelamento-page.component';
import { MaxPipe } from './pipes/max.pipe';
import { BannerLinksComponent } from './modulos/banner-links/banner-links.component';
import { PoliticaDeSegurancaPage } from './pages/politica-de-seguranca-page/politica-de-seguranca-page.component';
import { PoliticaDePrivacidadePage } from './pages/politica-de-privacidade-page/politica-de-privacidade-page.component';
import { ContatoPageComponent } from './pages/contato-page/contato-page.component';
import { ComboHorariosComponent } from './components/combos/combo-horarios/combo-horarios.component';
import { PassageirosModal } from './components/minhas-viagens/passageiros-modal/passageiros-modal.component';
import { SocialLoginModule } from "angularx-social-login";
import { CupomDescontoComponent } from './components/cupom/cupom-desconto/cupom-desconto.component';
import { FormatDateTextTwoPipe } from './pipes/format-date-text-two.pipe';
import { RecaptchaModule } from 'ng-recaptcha';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { BlogPage } from './pages/blog/blog-page/blog-page.component';
import { BlogItemPage } from './pages/blog/blog-item-page/blog-item-page.component';
import { DestinoPage } from './pages/destino-page/destino-page.component';
import { AcomodacoesPage } from './pages/acomodacao/acomodacoes-page/acomodacoes-page.component';
import { AcomodacaoPage } from './pages/acomodacao/acomodacao-page/acomodacao-page.component';
import { ModuloSobreComponent } from './modulos/modulo-sobre/modulo-sobre.component';
import { GaleriaCarrosselImagesComponent } from './components/galeria/galeria-carrossel-images/galeria-carrossel-images.component';
import { FaleConoscoModal } from './components/modais/fale-conosco-modal/fale-conosco-modal.component';
import { PartialMenuLinksUteisComponent } from './components/partial/partial-menu-links-uteis/partial-menu-links-uteis.component';
import { BlogComponent } from './modulos/blog/blog.component';
import { BlogPostItemComponent } from './components/blog/blog-post-item/blog-post-item.component';
import { DestinoComponent } from './modulos/destino/destino.component';
import { DestinoModuloPageComponent } from './pages/modulo/destino-modulo-page/destino-modulo-page.component';
import { AcomodacaoComponent } from './modulos/acomodacao/acomodacao.component';
import { BlogPartialMenuComponent } from './components/blog/blog-partial-menu/blog-partial-menu.component';
import { BlogPostsComponent } from './components/blog/blog-posts/blog-posts.component';
import { BlogPostItemTwoComponent } from './components/blog/blog-post-item-two/blog-post-item-two.component';
import { MapaGoogleComponent } from './components/mapa/mapa-google/mapa-google.component';
import { BlogSliderComponent } from './components/blog/blog-slider/blog-slider.component';
import { AcomodacaoItemComponent } from './components/acomodacao/acomodacao-item/acomodacao-item.component';
import { AcomodacaoItemTwoComponent } from './components/acomodacao/acomodacao-item-two/acomodacao-item-two.component';
import { ParcelamentoComponent } from './modulos/parcelamento/parcelamento.component';
import { AcomodacaoFormAjudaComponent } from './components/acomodacao/acomodacao-form-ajuda/acomodacao-form-ajuda.component';
import { AcomodacaoReservaComponent } from './components/acomodacao/acomodacao-reserva/acomodacao-reserva.component';
import { AcomodacaoQuartosComponent } from './components/acomodacao/acomodacao-quartos/acomodacao-quartos.component';
import { AcomodacaoCaracteristicaListaComponent } from './components/acomodacao/acomodacao-caracteristica-lista/acomodacao-caracteristica-lista.component';
import { QuartoItemComponent } from './components/acomodacao/quarto-item/quarto-item.component';
import { AcomodacaoReservaModal } from './components/acomodacao/acomodacao-reserva-modal/acomodacao-reserva-modal.component';
import { InputDateRangeComponent } from './components/input/input-date-range/input-date-range.component';

registerLocaleData(localePt,'pt-BR');   

@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        SobrePage,
        MatModalInfoDialogComponent,
        ModalConfirmComponent,
        IntegerDirective,
        PhoneMaskDirective,
        UppercaseDirective,
        SafeHtmlPipe,
        ModuloLogo,
        Modulo,
        GaleriaLightComponent,
        BreadcrumbsComponent,
        HeaderComponent,
        FooterComponent,
        WhatsappComponent,
        RedeSocialComponent,
        EnderecoComponent,
        UnidadesComponent,
        PageErrorComponent,
        LoaderComponent,
        MenuComponent,
        BannerSliderSlickComponent,
        MenuFooterComponent,
        NewsletterComponent,
        ContatoComponent,
        FaleConoscoComponent,
        LinksExternosComponent,
        DicasComponent,
        DicaItemComponent,
        BannerDestaqueComponent,
        LinkDestaquesComponent,
        FormatDuracaoPipe,
        SafeHtmlPipe,
        FormatValorManagerPipe,
        ComboManagerHotelComponent,
        GetTarifaMinimaListaPipe,
        MatSelectSearchComponent,
        TipoServicoPipe,
        FormatDateTextPipe,
        MaskDirective,
        ComboParcelasCartaoComponent,
        TipoPassageiroDescPipe,
        TrabalheConoscoPage,
        DateFormatTextPipe,
        ComboAeroportoComponent,
        ComboCiaAereaComponent,
        PoliticaDeCancelamentoPage,
        MaxPipe,
        BannerLinksComponent,
        PoliticaDeSegurancaPage,
        PoliticaDePrivacidadePage,
        ContatoPageComponent,
        ComboHorariosComponent,
        PassageirosModal,
        CupomDescontoComponent,
        FormatDateTextTwoPipe,
        BlogPage,
        BlogItemPage,
        DestinoPage,
        AcomodacoesPage,
        AcomodacaoPage,
        ModuloSobreComponent,
        GaleriaCarrosselImagesComponent,
        FaleConoscoComponent,
        FaleConoscoModal,
        PartialMenuLinksUteisComponent,
        BlogComponent,
        BlogPostItemComponent,
        DestinoComponent,
        DestinoModuloPageComponent,
        AcomodacaoComponent,
        BlogPartialMenuComponent,
        BlogPostsComponent,
        BlogPostItemTwoComponent,
        MapaGoogleComponent,
        BlogSliderComponent,
        AcomodacaoItemComponent,
        AcomodacaoItemTwoComponent,
        ParcelamentoComponent,
        AcomodacaoFormAjudaComponent,
        AcomodacaoReservaComponent,
        AcomodacaoQuartosComponent,
        AcomodacaoCaracteristicaListaComponent,
        QuartoItemComponent,
        AcomodacaoReservaModal,
        InputDateRangeComponent
    ],
    exports: [
        AcomodacaoItemTwoComponent,
        AcomodacaoCaracteristicaListaComponent,
        QuartoItemComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatRadioModule,
        MatOptionModule,
        DeviceDetectorModule.forRoot(),
        SocialLoginModule,
        RecaptchaV3Module,
        RecaptchaModule
    ],
    providers: [
        ApiService,
        AppService,
        DataService,
        ModuloService,
        ModulosResolver,
        GtagService,
        ServicoService,
        StorageService,
        ValidatorService,
        CartService,
        DateService,
        UsuarioService,
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcF5tYZAAAAAP97p284B0layluOZVRIe8k9oidb' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
