import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  constructor() { }

  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthStringTwo(month){

    let out   = "";

    switch(month){
      case 1:
        out = "Janeiro";
      break;
      case 2:
        out =  "Fevereiro";
      break;
      case 3:
        out =  "Março";
      break;
      case 4:
        out =  "Abril";
      break;
      case 5:
        out =  "Maio";
      break;
      case 6:
        out =  "junho";
      break;
      case 7:
        out =  "julho";
      break;
      case 8:
        out =  "Agosto";
      break;
      case 9:
        out =  "Setembro";
      break;
      case 10:
        out =  "Outubro";
      break;
      case 11:
        out =  "Novembro";
      break;
      case 12:
        out =  "Dezembro";
      break;
    }

    return out;

  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthString(value){

    let data  = new Date(value);
    let month = data.getMonth()+1; 
   
    let out   = "";

    switch(month){
      case 1:
        out = "Janeiro";
      break;
      case 2:
        out =  "Fevereiro";
      break;
      case 3:
        out =  "Março";
      break;
      case 4:
        out =  "Abril";
      break;
      case 5:
        out =  "Maio";
      break;
      case 6:
        out =  "junho";
      break;
      case 7:
        out =  "julho";
      break;
      case 8:
        out =  "Agosto";
      break;
      case 9:
        out =  "Setembro";
      break;
      case 10:
        out =  "Outubro";
      break;
      case 11:
        out =  "Novembro";
      break;
      case 12:
        out =  "Dezembro";
      break;
    }

    return out;

  }
  /**
   * 
   * Retorna o dia da data
   * 
   */
  getDay(value){

    let data = new Date(value);

    return data.getUTCDate() < 10 ? "0"+data.getUTCDate() : data.getUTCDate();

  }
  /**
   * 
   * Retorna o ano da data
   * 
   */
  getYear(value){
    
    let data = new Date(value);
        
    return data.getFullYear();
    
  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonth(value){
    
    let data  = new Date(value);
    let month = data.getUTCMonth()+1;

    let out   = month < 10 ? "0"+month : month;
    
    return out;
    
  }
  /**
   * 
   * Get date today
   *  
   */
  today(separator="-"){

    let date = new Date();

    let day       = date.getUTCDate();
    let month:any = date.getUTCMonth();
    month = month+1;
    if(month  < 10){
      month = "0"+month;
    }
    let year  = date.getUTCFullYear();
    
    return day+separator+month+separator+year;

  }

}
