import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';

declare var $:any;

@Component({
  selector: 'galeria-light',
  templateUrl: './galeria-light.component.html',
  styleUrls: ['./galeria-light.component.scss']
})
export class GaleriaLightComponent implements OnInit,AfterViewInit {
  
  @Input("fotos") fotos             = []; 
  @ViewChild("galeria") galeria:ElementRef;  
  @Input("classCustom") classCustom = "";
  @Input("template") template       = null;
  @Input("open") open               = new EventEmitter(); 
  public defaultImage               = "/images/sem-foto/default.jpg";  
  public _galeria = null;

  constructor(){ }

  /**
   * 
   * Init Gallery
   * 
   */
  initGallery(){

    this._galeria = $(this.galeria.nativeElement).lightGallery({
      thumbnail:true
    });  

  } 
  /**
   * 
   * On Events
   * 
   */
  _onEvents(){

    this.open.subscribe((data) => {
      $(this.galeria.nativeElement).find("a")[0].click();
    });

  }
  /***  
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this._onEvents();
  }
  /**
   * 
   * Init funções
   * 
   */
  ngAfterViewInit(): void { 
    this.initGallery(); 
  }

}
