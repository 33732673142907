<div class="modulo modulo-link-destaques">
    <div class="modulo-content">
        <ul>
            <li class="li-bustour">
                <div class="item-content">
                    <div class="row">
                        <div class="col-md-6"> 
                            <div class="titulo">
                                <span>LOCALIZE-SE NA CIDADE</span><br>
                                <span>ATRAVÉS DO APP DO</span><br>
                                <strong>BUSTOUR!</strong>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-6">
                                    <div class="links"> 
                                        <a href="https://play.google.com/store/apps/details?id=br.com.bustour" target="_blank">
                                            <figure>
                                                <img defaultImage="/images/pixel.png"  lazyLoad="/images/icons/apps/google-play.svg" alt="Google Play - App Bustour" />
                                            </figure>  
                                        </a> 
                                        <a href="https://play.google.com/store/apps/details?id=br.com.bustour" target="_blank"> 
                                            <figure>
                                                <img defaultImage="/images/pixel.png"  lazyLoad="/images/icons/apps/apple-store.svg" alt="Apple Store - App Bustour" />
                                            </figure>    
                                        </a>    
                                    </div>
                                </div> 
                                <div class="col-md-6 col-6">
                                    <div class="qrcode"> 
                                        <img defaultImage="/images/pixel.png"  lazyLoad="/images/bustour/qrcode.png" alt="Qrcode Bustour - App Bustour" />
                                    </div>
                                </div>
                            </div>          
                        </div>
                        <div class="col-md-6">
                            <div class="celular">
                                <a href="https://bustour.com.br/" target="_blank"> 
                                    <img defaultImage="/images/pixel.png"  lazyLoad="/images/bustour/app-bustour.png" alt="App Bustour - Brocker Turismo" />
                                </a> 
                                <div class="logo"> 
                                    <img defaultImage="/images/pixel.png"  lazyLoad="/images/bustour/logo.svg" alt="Logo Bustour Serra Gaúcha" />
                                </div>   
                            </div> 
                        </div>    
                    </div>            
                </div>    
            </li>
            <li class="li-blog">
                <div class="item-content">
                    <div class="titulo">
                        <span>DICAS COMPLETAS</span><br>
                        <span>SOBRE A REGIÃO EM</span><br>
                        <span>NOSSO BLOG!</span>
                    </div>  
                    <div class="saiba-mais">
                        <div class='content'>
                            <a href="https://blog.brockerturismo.com.br/" target="_blank" title="Blog - Brocker Turismo">
                                CONFIRA! 
                            </a> 
                        </div>        
                    </div>    
                </div>
            </li>
        </ul>    
    </div>    
</div>    
