import { Component } from '@angular/core';
import { AppService } from './services/app.service';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { GtagService } from './services/gtag.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(
    private app: AppService,
    private router: Router,
    private gtag: GtagService
  ){

    this.onEvents()
  
  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        
      }
      
      if(event instanceof NavigationEnd) {

        setTimeout(()=>{
          
        },200)

      }

    });
    this.app.emitChangePage.subscribe(response => {
      
    });
    this.app.setStatusServidorEmitter.subscribe(code => {
      //this.statusCode = code;
    });
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      
      setTimeout(() => { 
        this.gtag.sendPagePath(event.urlAfterRedirects); 
      },1000);

    }); 


  }


}
