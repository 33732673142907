import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit,AfterViewInit {

  @Input("data") data;
  @Input("template") template = null; 
  @Input("classCustom") classCustom = ""; 
  @ViewChild("slider") slider:ElementRef;
  
  constructor(){}

  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 4,
      dots: false, 
      arrows: false,
      centerMode: false,
      centerPadding: '5px',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider(); 
  }

}
