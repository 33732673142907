import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-banner-destaque',
  templateUrl: './banner-destaque.component.html',
  styleUrls: ['./banner-destaque.component.scss']
})
export class BannerDestaqueComponent implements OnInit, AfterViewInit {

  @Input("data")  data               = null;
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null;  
  @ViewChild("banner") banner:ElementRef;
  public banners                     = [];

  constructor(){}
  
  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({
      infinite: true,
      autoplay: true,
      dots: true
    });
    $(this.banner.nativeElement).slick('refresh');

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {}
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
