import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'partial-menu-links-uteis',
  templateUrl: './partial-menu-links-uteis.component.html',
  styleUrls: ['./partial-menu-links-uteis.component.scss']
})
export class PartialMenuLinksUteisComponent implements OnInit {

  constructor() { }

  /***
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
