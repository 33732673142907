import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'acomodacao-reserva-modal',
  templateUrl: './acomodacao-reserva-modal.component.html',
  styleUrls: ['./acomodacao-reserva-modal.component.scss']
})
export class AcomodacaoReservaModal implements OnInit {

  public dataItem:any = null;
  public loader       = false;
  public toClear      = new EventEmitter();

  constructor(
    public dialog: MatDialogRef<AcomodacaoReservaModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    public app: AppService,
    public api: ApiService,
    public validator: ValidatorService
  ){

    this.initDataItem();

  }
  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe seu Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe seu E-mail.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe seu Telefone.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail é inválido.");
      this.validator.isEmpty(this.dataItem.date_in,"date_in","- Preencha um período.");
      this.validator.isEmpty(this.dataItem.date_out,"email","- Preencha um período.");

      if(!this.validator.passes()){

        this.app.info(this.validator.getMessagesHtml());
        return false;

      }

      this.loader = true;

      this.api.contato().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Recebemos suas informações. Em breve entraremos em contato.");
          this.toClear.emit(null);
          this.initDataItem();

        }else{

          let error = this.api.formatError(response.data);
          this.app.info(error.message);

        }

      },(response) => {

        this.loader = false;
        let error = this.api.formatError(response.data);
        this.app.info(error.message);

      });



    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Close
   * 
   */
  _close(){

    this.dialog.close();

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      date_in: null,
      date_out: null,
      acomodacao_id: this.data.params.id,
      acomodacao_nome: this.data.params.nome,
      tipo: 5,
      apelido: "reserva"
    }


  }
  /***
   * 
   * Change
   * 
   */
  _changeDate(data){

    this.dataItem.date_in  = data.date_in;
    this.dataItem.date_out = data.date_out;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
  }

}
