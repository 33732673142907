import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public emitUsuarioLogin = new EventEmitter(); 

  constructor(){}

}
