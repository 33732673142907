<div class="modulo modulo-acomodacao">
      <div class="container">
            <div class="row">
                  <div class="col-lg-6 col-md-12">
                        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
                              <h2>{{data.titulo}}</h2>
                              <div class="bolinhas">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                              </div>
                              <h3>{{data.subtitulo}}</h3>
                        </div>
                  </div>
                  <div class="col-lg-6 col-md-12"> 
                        <div class="modulo-content">
                              <div class="row">
                                    <div class="col-md-6" *ngFor="let d of data.data">
                                          <acomodacao-item [data]="d"></acomodacao-item>
                                    </div>      
                              </div>      
                        </div>
                  </div> 
            </div>  
      </div> 
      <div class="content-bg">
          <div class='bg bg-1'></div>
          <div class='bg bg-2'></div>
      </div>
</div>      
