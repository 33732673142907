import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
  
  @ViewChild("header") header:ElementRef;

  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
  setTopFixed(){

    let header = this.header.nativeElement;

    $(window).scroll(function(){  
      
      var fromTop = $(this).scrollTop();
      var windowWidth = $(window).width();
      
      if(fromTop > 10){
        header.classList.add("fixed");
      }else{
        header.classList.remove("fixed");
      }

    });

  }
  ngOnInit():void{
  
  }
  ngAfterViewInit(): void {
    this.setTopFixed();
  }

}
