import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'acomodacao-form-ajuda',
  templateUrl: './acomodacao-form-ajuda.component.html',
  styleUrls: ['./acomodacao-form-ajuda.component.scss']
})
export class AcomodacaoFormAjudaComponent implements OnInit {

  @Input("id") id     = null;
  @Input("nome") nome = null;
  public loader       = false;
  public dataItem:any = {}; 

  constructor(
    private app: AppService,
    private api: ApiService,
    private validator: ValidatorService
  ){ }

  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe o Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail informado é inválido.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe o Telefone.");
      this.validator.isEmpty(this.dataItem.mensagem,"mensagem","- Escreva a sua dúvida.");
    
      if(!this.validator.passes()){

        this.app.info(this.validator.getMessagesHtml());
        return false;

      }

      this.loader = true;

      this.api.contato().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.app.info("Recebemos a sua dúvida. Em breve entraremos em contato;");
          this.initDataItem(); 

        }else{

          let error = this.api.formatError(response);
          this.app.info(error.message);

        }
        
      },(response) => {

        this.loader = false;
        let error = this.api.formatError(response);
        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Iit data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      acomodacao_id: this.id,  
      acomodacao_nome: this.nome,
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      tipo: 4, 
      apelido: "ajuda" 
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}
