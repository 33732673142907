<div [ngSwitch]="template">
  <div *ngSwitchCase="null" class="single">
    <img src="/images/icons/alltour/whatsapp.svg" alt="Whatsapp - Aextour" (click)="open()" />
  </div>
  <div *ngSwitchCase="'topo'" class="whatsapp-topo" (click)="open()">
    <a href="javascript::void();" class="btn-whatsapp" title="Whatsapp - Brocker Turismo">
      <span>Fale Conosco WhatsApp</span>
    </a>  
  </div>
  <div *ngSwitchCase="'page-pagamento'"> 
    <div class="modulo modulo-page-pagamento">  
      <div class="modulo-content">
        <ul (click)="open()">
          <li>
            <div class="icon">  
              <i class="fab fa-whatsapp"></i>
            </div>  
          </li>
          <li>
            <span>
              fala com a gente
            </span>  
            <span>
              pelo whatsapp ;)
            </span>  
          </li>
        </ul>
      </div>  
    </div>  
  </div> 
  <div *ngSwitchCase="'fixo'" class="fixo desktop" (click)="open()" #fixo>
    <div class="box-whats pulsing">
      <i class="fab fa-whatsapp"></i>
    </div> 
  </div> 
  <div *ngSwitchCase="'fixo'" class="fixo mobile" (click)="open()">
    <div class="box-whats pulsing">
      <i class="fab fa-whatsapp"></i>
    </div>
  </div>
  <div *ngSwitchCase="'acomodacoes'" class="default-acomodacoes" (click)="open()">
    <span class="title">Se preferir fale com nós por WhatsApp</span>
    <div class="content">
      <div class="box-whats">
        <i class="fab fa-whatsapp"></i>
      </div>
      <span>Clique agora aqui</span>
    </div>
  </div>
</div>
