import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { FbService } from 'src/app/services/fb.service';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPage implements OnInit,OnDestroy {

  public loader   = false;
  private apelido = "blog"; 
  public response:any = {
    data: "",
    status: null
  };
  public page             = null;
  public fotos            = []; 
  public params:any       = {};
  public categorias       = [];
  public itensBreadcrumbs = [];
  public apelidoCategoria = null;
  public mySubscription:Subscription;
  public destroyed               = new Subject<any>();
  public destroy                 = false;
  public posts:any = null;

  constructor(
    private api:ApiService,
    private app: AppService,
    private router: Router,
    private route: ActivatedRoute
  ){ 

    this.apelidoCategoria = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
    
  }
  /**
   * 
   * Retorna os dados
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true; 
      
      this.api.conteudo().page(this.apelido,{
        apelido: this.apelidoCategoria
      }).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page       = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.categorias = response.data.page.categorias;
            this.posts      = response.data.page.posts;

            let title = this.page.meta_title+" - "+this.posts.categoria.titulo;

            this.app.setMetaDados(title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = { 
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Sobre a Pousada"
    });

  }

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop();
    this.getData(); 
    this.setItensBreadcrumbs();

    this.mySubscription = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {

      if(!this.destroy){
        
        let apelido = this.route.params["_value"].apelido;
        if(typeof(apelido) != "undefined"){
          this.apelidoCategoria = apelido; 
          this.getData();
        }  
      }

    });

  }
  ngAfterViewInit(): void {
    
  }
  /**
   * 
   * Destroy
   * 
   * 
   */
  ngOnDestroy() {
    
    if(this.mySubscription){
      
      this.destroy         = true;
      this.mySubscription.unsubscribe(); 
    
    }

  }

}
