<nav class="menu-footer">
    <ul>
        <li>
            <a routerLink="/quem-somos" title="Sobre - Brocker Turismo">
                <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/menu/sobre.svg" alt="Sobre - Brocker Turismo" />
                <span>Sobre</span>
            </a>    
        </li> 
        <li> 
            <a routerLink="/fotos" title="Fotos - Brocker Turismo">
                <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/menu/fotos.svg" alt="Fotos - Brocker Turismo" />
                <span>Fotos</span>
            </a>    
        </li> 
        <li>
            <a routerLink="/trabalhe-conosco" title="Trabalhe Conosco - Brocker Turismo">
                <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/menu/trabalhe-conosco.svg" alt="Trabalhe Conosco - Brocker Turismo" />
                <span>Trabalhe Conosco</span>
            </a>    
        </li> 
        <li>
            <a routerLink="/imprensa" title="Imprensa - Brocker Turismo">
                <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/menu/imprensa.svg" alt="Imprensa - Brocker Turismo" />
                <span>Imprensa</span>
            </a>    
        </li>    
    </ul>    
</nav>
