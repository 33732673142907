<div class="quarto-item">
      <div class="images">
            <div class="slider-itens" #slider> 
                  <div class="slider-item" *ngFor="let d of data.fotos">
                      <img [src]="d.imagem" [alt]="d.titulo" class="img-fluid" />
                  </div>      
            </div>
            <div class="swiper-button-prev sw-btn" (click)="prev()">
                  <img src="/images/icons/slider/prev-02.svg" alt="Arrow" />
            </div>
            <div class="swiper-button-next sw-btn" (click)="next()">
                  <img src="/images/icons/slider/next-02.svg" alt="Arrow" />
            </div> 
      </div>    
      <div class="content">
            <h3>{{data.nome}}</h3>
            <div class="linha"></div>
            <ul class="caracteristicas">
                  <li *ngFor="let c of data.caracteristicas">
                        <img [src]='c.icone' [alt]="c.titulo" />
                        <span>{{c.titulo}}</span>
                  </li>      
            </ul>
      </div>      
</div>