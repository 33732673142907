<div class="modulo banner-slider-slick animated fadeIn" (window:resize)="_onResize()">
  <div class="swiper-button-prev sw-btn" (click)="prevSlide()">
    <i class="fal fa-long-arrow-left"></i>
  </div>
  <div class="swiper-button-next sw-btn" (click)="nextSlide()"> 
    <i class="fal fa-long-arrow-right"></i>
  </div> 
  <div #banner class="banner-slider-slick-content">
    <div *ngFor="let i of data.banners;let y = index">
      <div class="item slider-item" *ngIf="!i.link_ativo">
        <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width > 700" class="img-fluid" />
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width < 700 && !i.ativar_imagem_mobile" class="img-fluid" />
            <img [src]="i.imagem_mobile" [alt]="i.titulo" *ngIf="width <= 700 && i.ativar_imagem_mobile" class="img-fluid" />
      </div>  
      <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
        <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width > 700"  />
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width < 700 && !i.ativar_imagem_mobile"  />
            <img [src]="i.imagem_mobile" [alt]="i.titulo" *ngIf="width <= 700 && i.ativar_imagem_mobile" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width > 700"  />
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width < 700 && !i.ativar_imagem_mobile"  />
            <img [src]="i.imagem_mobile" [alt]="i.titulo" *ngIf="width <= 700 && i.ativar_imagem_mobile"  />
          </div>  
        </a> 
        <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width > 700" class="img-fluid" />
            <img [src]="i.imagem" [alt]="i.titulo" *ngIf="width < 700 && !i.ativar_imagem_mobile" />
            <img [src]="i.imagem_mobile" [alt]="i.titulo" *ngIf="width <= 700 && i.ativar_imagem_mobile"  />
          </div>  
        </a>   
      </div>
    </div>
  </div>
</div>


