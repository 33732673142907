import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var window:any;
declare var $:any;

@Component({
  selector: 'modulo-sobre',
  templateUrl: './modulo-sobre.component.html',
  styleUrls: ['./modulo-sobre.component.scss']
})
export class ModuloSobreComponent implements OnInit,AfterViewInit {

  @Input("data") data;
  @Input("template") template; 
  @Input("classCustom") classCustom; 
  @ViewChild("fotos") fotos:ElementRef;

  constructor( 
  ) { }
  

  /**  
   * 
   * Set init
   * 
   */
  setWidth(width=null){

    let w = width == null ? window.innerWidth : width;

    $(this.fotos.nativeElement).width(w);

  }
  /**
   * 
   * Resize
   * 
   */
  _resize(event){

    let width = event.currentTarget.innerWidth;

    this.setWidth(width);

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{ 
  }
  ngAfterViewInit(): void {
    this.setWidth();
  }

}
