<div class="blog-posts">
      <div class="header">
            <h3>{{categoria.titulo}}</h3>
      </div>  
      <div class="content">
            <section class="posts" *ngIf="posts.length > 0"> 
                  <div class="row">
                        <div class="col-md-4" *ngFor="let p of posts">
                              <blog-post-item-two [data]="p"></blog-post-item-two>
                        </div>      
                  </div>  
            </section>
            <section class="posts" *ngIf="posts.length == 0"> 
                  <span class="message-default">
                        Ainda não temos posts para esta categoria.
                  </span>
            </section>  
      </div>
</div>      
