import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { AcomodacaoReservaModal } from '../acomodacao-reserva-modal/acomodacao-reserva-modal.component';

@Component({
  selector: 'acomodacao-reserva',
  templateUrl: './acomodacao-reserva.component.html',
  styleUrls: ['./acomodacao-reserva.component.scss']
})
export class AcomodacaoReservaComponent implements OnInit {

  @Input("id") id     = null;
  @Input("nome") nome = null;

  constructor(
    private app: AppService,
    private dialog: MatDialog 
  ){}

  /**
   * 
   * Open
   * 
   */
  open(){ 

   this.dialog.open(AcomodacaoReservaModal,{
    width: "800px",
    data: {
      params: {
        id: this.id,
        nome: this.nome
      }
    }
   });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
