import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'acomodacao-page',
  templateUrl: './acomodacao-page.component.html',
  styleUrls: ['./acomodacao-page.component.scss']
})
export class AcomodacaoPage implements OnInit {

  public apelido                 = null;
  public apelidoCategoria        = null;
  public loader = false;
  public mySubscription:Subscription;
  public destroyed               = new Subject<any>();
  public destroy                 = false;
  public response = {
    data: null,
    status: null
  }
  public data:any         = null;
  public itensBreadcrumbs = [];
  public caracteristicas  = [];
  
  constructor(
    public api: ApiService,
    public app: AppService,
    private router: Router,
    private route: ActivatedRoute
  ){

    this.apelido          = typeof(this.route.params["_value"].apelido) != "undefined" ? this.route.params["_value"].apelido : null;
    this.apelidoCategoria = typeof(this.route.params["_value"].apelido_categoria) != "undefined" ? this.route.params["_value"].apelido_categoria : null;
  
  } 
  /**
   * 
   * Get data
   * 
   */
  getData(){

    try{

      this.loader = true;  

      this.api.acomodacao().show(this.apelido).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data.acomodacao === null || response.data.acomodacao === "null"){
            this.router.navigateByUrl("/");
          }else{
            this.data            = response.data;
            this.setItensBreadcrumbs();
            this.app.setMetaDados(this.data.acomodacao.meta_title,this.data.acomodacao.meta_description);

            this.response.status = 1;
          }

        }else{

          let error   = this.api.formatError(response);
          this.response = {
            data: error.message,
            status: 500
          }

        }

        setTimeout(() => {
          this.app.toTop();
        },500);

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;
      this.response = {
        data: e.message,
        status: 500
      }

    }

  }
   /***
   * 
   * Set breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Ancomodações",
      routerLink: "/acomodacoes"
    });
    this.itensBreadcrumbs.push({
      text: this.data.acomodacao.nome
    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.getData();
  }

}
