import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-parcelamento',
  templateUrl: './parcelamento.component.html',
  styleUrls: ['./parcelamento.component.scss']
})
export class ParcelamentoComponent implements OnInit {

  @Input("data") data;
  @Input("template") template; 
  @Input("classCustom") classCustom; 
  
  
  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}
