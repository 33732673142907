import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { GtagService } from 'src/app/services/gtag.service';
import { error } from 'protractor';
@Component({
  selector: 'modulo-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  
  @Input("data") data;
  @Input("template") template; 
  @Input("classCustom") classCustom; 
  public dataItem:any = {};
  public loader       = false; 
  public message = { 
    data: "",
    class: "",
    show: false 
  };

  constructor(
    private app: AppService,
    private api: ApiService,
    private gtag: GtagService
  ){ }

  /**
   * 
   * Envia os dados do contato para a news
   * 
   */
  send(){

    try{

      this.loader = true;
      this.gtag.submitNewsletter();

      this.api.contato().store(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:
   
            this.app.info("Inscrição realizada com sucesso.");
            this.initDataItem();
            
          break;
          default:
            this.app.info("Houve um erro interno. Tente mais tarde ou informe nossa equipe.");
          break;
        }

      },(response) => {

        this.loader  = false; 
        let errors   = this.api.formatError(response);
        this.app.info(errors.message);


      });


    }catch(e){

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      apelido: "newsletter",
      tipo: 1
    }

  }
  /**
   * 
   * Converte para string o dataItem
   * 
   */
  toString(){

    return JSON.stringify(this.dataItem);
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
    this.initDataItem();
  }
  

}
