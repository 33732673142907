<div class="btn-menu-content">
    <button type="button" class="btn-menu-open fadeIn" (click)="_open()">
        <div class="nav-button">
            <i class="fas fa-bars"></i>
        </div>   
    </button>
</div>     
<nav class="menu {{classCustom}}" [ngClass]="{'show': openMenu}">
    <div class="menu-header">
        <div class="close" (click)="_close()">
            <i class="fas fa-times"></i>
        </div>    
    </div>    
    <ul>
        <li>
            <a routerLink="/sobre-a-pousada" title="A Pousada" routerLinkActive="active"> 
                <span class="icon">  
                    <img src="/images/logos/simbolo.svg" alt="A Pousada" />
                </span>
                <span>A Pousada</span>
                <span class="linha"></span>
            </a>    
        </li> 
        <li>
            <a routerLink="/acomodacoes" title="Acomodações" routerLinkActive="active">    
                <span class="icon">
                    <i class="fas fa-bed"></i>
                </span>
                <span>Acomodações</span>
                <span class="linha"></span>
            </a>    
        </li> 
        <li>
            <a routerLink="/destino" title="Destino" routerLinkActive="active">   
                <span class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                </span>
                <span>Destino</span>
                <span class="linha"></span>
            </a>    
        </li> 
        <li> 
            <a routerLink="/blog" title="Blog" routerLinkActive="active">
                <span class="icon">
                    <i class="fas fa-comment"></i>
                </span>    
                <span>Blog</span>
                <span class="linha"></span>
            </a>  
        </li>
        <li>
            <a routerLink="/contato" title="Contato" routerLinkActive="active">  
                <span class="icon">
                    <i class="fas fa-envelope"></i>
                </span>    
                <span>Contato</span>
                <span class="linha"></span>
            </a>  
        </li>     
    </ul>    
</nav>
<div class="bg-overlay">
    <div class="overlay" [ngClass]="{'show': openMenu}"></div>  
</div>    
     
