import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';
import { startWith, map } from 'rxjs/operators'; 
import { Observable } from 'rxjs';

@Component({
  selector: 'combo-cia-aerea',
  templateUrl: './combo-cia-aerea.component.html',
  styleUrls: ['./combo-cia-aerea.component.scss']
})
export class ComboCiaAereaComponent implements OnInit {

  @Input("label") label = "CIA AÉREA:";
  @Input("value") value = null;
  @Input("text") text   = null; 
  @ViewChild("select") select:MatSelect;
  @Output("changeValue") changeValue = new EventEmitter();
  public comboCtrl        = new FormControl();
  public comboFilter:Observable<any>;
  public loader         = false;
  public response       = {
    data: [],
    status: null,
    message: ""
  };
  public paramsAtributos = {
    text: "text",
    value: "codigo"
  }
  
  constructor(
    private api: ApiService,
    private app: AppService
  ){ }

  /**
   * 
   * Retorna os dados para o ComboBox
   * 
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().cia_aerea.combo().subscribe(response => {

        this.loader = false;
        
        if(response.status == 1){

          this.response = response;

          this.filteredData();
          
          setTimeout(() => {
            this.select.open();
          },100);
        
        }else{

          this.response = {
            data: [],
            status: 500,
            message: response.data
          }

        }  

      },(response) => {

        this.loader = false;

        let error     = this.app.formatError(response);
        this.response = {
          data: [],
          status: 500, 
          message: error.message
        };

      });

    }catch(e){

      this.loader = false;

    }

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }

  }
  /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null;

    for(let index = 0; index < this.response.data.length; index++) {

      if(this.value == this.response.data[index]["codigo"]){

        data = this.response.data[index];
        break;

      }
      
    }

    return data;

  }
  /**
  * 
  * 
  * 
  */
 _getData(){
    
  if(this.response.status != 1){
    this.getData();
  }

}
  /**
   * 
   * Filter
   * 
   */
  filteredData(){

    this.comboFilter = this.comboCtrl.valueChanges
    .pipe(
      startWith(''),
      map(state => state ? this._filterData(state) : this.response.data.slice())
    );

  }
  /**
   * 
   * Fitra
   * 
   */
  private _filterData(value: string) {
    
    const filterValue = this.app.removerAcentos(value.toLowerCase());

    return this.response.data.filter(data => this.app.removerAcentos(data[this.paramsAtributos.text]).toLowerCase().indexOf(filterValue) != -1);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }

}
