import { Component, OnInit, Input } from '@angular/core';

declare var window:any;

@Component({
  selector: 'dica-item',
  templateUrl: './dica-item.component.html',
  styleUrls: ['./dica-item.component.scss']
})
export class DicaItemComponent implements OnInit {

  @Input("data") data:any = {};
  public defaultImage = "/images/produto/sem-foto/brocker.jpg";

  constructor() { }

  /**
   * 
   * On click  
   * 
   */
  onClick(){

    window.open(this.data.link,"_blank");

  }
  /**
   * 
   * Inicialza as Funções
   * 
   */
  ngOnInit(): void {}

}
