<div class="page page-contato">
  <div *ngIf="response.status == 1"> 
    <div class="page-title" *ngIf="page.mostrar_titulo">
      <div class="container">
        <h1>{{page.titulo}}</h1>
        <h2 *ngIf="page.mostrar_subtitulo">
          {{page.subtitulo}}
        </h2>
      </div> 
      <div class="linha"></div> 
    </div> 
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <section class="section section-form">
              <form class="form" (submit)="send()" autocomplete="off">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" [(ngModel)]="dataItem.nome" placeholder="Informe seu nome*" name="nome" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" [(ngModel)]="dataItem.email" placeholder="Informe seu e-mail*" name="email" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" [(ngModel)]="dataItem.telefone" placeholder="Telefone" name="telefone" mask="(99) 99999-9999" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" [(ngModel)]="dataItem.assunto" placeholder="Informe o assunto*" name="assunto" />
                    </div>
                  </div>
                </div>       
                <div class="form-group">
                  <textarea type="text" [(ngModel)]="dataItem.mensagem" placeholder="Escreva sua mensagem..." name="mensagem"></textarea>
                </div> 
                <div class="linha"></div>  
                <div class="form-group">
                  <button type="submit" [disabled]="loader">
                    ENVIAR
                  </button>  
                </div>  
            </form>  
          </section> 
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="box-item">
              <div class="center">
                <modulo posicao="rede-social" template="page-contato"></modulo>
              </div>
            </div>
            <div class="box-item">
              <div class="center">
                <modulo posicao="contato" template="email"></modulo>
              </div>
            </div>
            <div class="box-item">
              <div class="center">
                <modulo posicao="contato" template="telefones"></modulo>
              </div>
            </div>  
          </div>  
        </div> 
      </div> 
    </div> 
  </div> 
  <div *ngIf="response.status == 500">
    <div class="container">
      <page-error [data="response.data" (reload)="getData()"></page-error>
    </div>  
  </div>  
</div>
<loader [hidden]="!loader"></loader>
