<div class="page page-sobre" itemtype="https://schema.org/AboutPage">  
      <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
        <div class="page-title" *ngIf="page.mostrar_titulo">
          <div class="container">
            <h1 itemprop="name">{{page.titulo}}</h1>
            <h2 *ngIf="page.mostrar_subtitulo">{{page.subtitulo}}</h2>
          </div>  
        </div>  
        <div class="page-content">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <blog-partial-menu [data]="categorias" [apelido]="apelidoCategoria"></blog-partial-menu>
              </div>
              <div class="col-md-9"> 
                <blog-posts [data]="posts" *ngIf="!loader"></blog-posts>
              </div>
            </div> 
          </div>   
        </div> 
      </div> 
      <div *ngIf="response.status == 500"> 
        <div class="container"> 
          <page-error [data]="response.data" (reload)="getData()"></page-error>
        </div>  
      </div>  
</div>  
<loader message="Carregando Conteúdo" [hidden]="!loader"></loader> 
        
