<div class="acomodacao-reserva">
      <div class="header">
            <span>Reserve em até</span>
            <strong>3x sem juros</strong>
      </div>    
      <div class="content">
           <button type="button" (click)="open()">
                 <div class="center">
                        <i class="fas fa-calendar-alt"></i>
                        <span>Quero reservar</span>
                 </div>
           </button> 
      </div>      
</div>