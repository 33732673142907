import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'politica-de-seguranca-page',
  templateUrl: './politica-de-seguranca-page.component.html',
  styleUrls: ['./politica-de-seguranca-page.component.scss']
})
export class PoliticaDeSegurancaPage implements OnInit {

  
  public loader   = false;
  private apelido = "politicas-de-seguranca"; 
  public response:any = {
    data: "",
    status: null
  };
  public page             = null;
  public fotos            = []; 
  public params           = {};
  public equipe           = [];
  public itensBreadcrumbs = [];

  constructor(
    private api:ApiService,
    private app: AppService
  ){ }


  /**
   * 
   * Retorna os dados
   * 
   * 
   */
  getData(){ 

    try{
      
      this.loader = true;
      
      this.api.conteudo().page(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.params    = JSON.parse(this.page.params);

            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /**
   * 
   * Set itens
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Políticas de Segurança" 
    });

  }
  /**
   * 
   * Inicialza as Funções
   * 
   */
  ngOnInit(){ 
    this.app.toTop();
    this.getData();
    this.setItensBreadcrumbs(); 
  }

}
