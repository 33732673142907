<header #header class="animated-two fadeIn">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-3"> 
                    <div class="center">
                        <modulo posicao="logo"></modulo>
                    </div>    
                </div>  
                <div class="col-lg-9 col-md-6 col-9"> 
                    <div class="content-menu">
                        <partial-menu></partial-menu> 
                    </div>      
                </div>    
            </div>    
        </div>    
    </div>
    <div class="content-menu-mobile">
        <partial-menu classCustom="menu-mobile" class="container-menu-mobile"></partial-menu>
    </div>    
</header>    