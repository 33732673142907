<nav>
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"
        *ngFor="let item of listItens;let i=index;let last=last;" [ngClass]="{'active':last}"
      >
        <a *ngIf="!last && !item.routerLink">{{item.text}}</a>
        <a [routerLink]="item.routerLink" *ngIf="!last && item.routerLink">{{item.text}}</a>
        <span class="bolinha"*ngIf="!last"></span>
        <span *ngIf="last">{{item.text}}</span>
      </li>
    </ol>
  </div>  
</nav>