<div class="page page-sobre" itemtype="https://schema.org/AboutPage">  
      <div *ngIf="response.status == 1" itemprop="mainContentOfPage" itemscope itemtype="https://schema.org/WebPageElement">
        <div class="section-breadcrumbs" *ngIf="itensBreadcrumbs.length > 0">
          <breadcrumbs home="false" [itens]="itensBreadcrumbs"></breadcrumbs>
        </div> 
        <div class="page-title">
          <div class="container">
            <h1 itemprop="name">{{data.acomodacao.nome}}</h1>
            <h2>{{data.acomodacao.subtitulo}}</h2>
          </div>  
        </div>  
        <div class="page-content">
          <div class="container">
            <section class="section section-sobre">
              <div class="row">
                <div class="col-lg-9 col-md-8">
                  <div class="box">
                    <h4 class="title">SOBRE</h4>  
                    <article [innerHtml]="data.acomodacao.descricao | safeHtml"></article>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4">
                  <div class="box">
                    <acomodacao-reserva [id]="data.acomodacao.id" [nome]="data.acomodacao.nome"></acomodacao-reserva>
                  </div>  
                </div>  
              </div>  
            </section>  
          </div>
          <section class="section section-quartos" *ngIf="data.quartos != null">
            <div class="section-title">
              <div class="container">
                <h2>Quartos</h2>
              </div>
            </div>
            <div class="section-content">
              <div class="container">
                <div class="row">
                  <div class="col-lg-4 col-md-6" *ngFor="let i of data.quartos">
                    <quarto-item [data]="i"></quarto-item> 
                  </div>  
                </div>  
              </div>
            </div>
          </section>
          <section class="section section-caracteristicas" *ngIf="data.caracteristicas != null">
            <div class="section-title">
              <div class="container">
                <h2>Veja o que os nossos quartos incluem</h2>
              </div>
            </div>
            <div class="section-content">
              <div class="container"> 
                <acomodacao-caracteristica-lista [data]="data.caracteristicas"></acomodacao-caracteristica-lista>
              </div>  
            </div>
          </section>
          <section class="section section-contato">
            <div class="section-title">
              <div class="container">
                <h2>Precisa de ajuda para escolher a sua acomodação?</h2>
              </div>
            </div>  
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <acomodacao-form-ajuda [id]="data.acomodacao.id" [nome]="data.acomodacao.nome"></acomodacao-form-ajuda>
                </div>
                <div class="col-md-4">
                  <modulo posicao="whatsapp" template='acomodacoes'></modulo>
                </div>
                <div class="col-md-4"> 
                  <modulo posicao="parcelamento"></modulo>
                </div> 
              </div>  
            </div>  
          </section> 
        </div> 
      </div> 
      <div *ngIf="response.status == 500"> 
        <div class="container"> 
          <page-error [data]="response.data" (reload)="getData()"></page-error>
        </div>  
      </div>  
</div>  
<loader [hidden]="!loader"></loader> 
        
