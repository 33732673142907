import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class ModuloLogo implements OnInit {

  @Input("data") data;
  public logo = null;
  @Input("classCustom") classCustom = "";
  @Input("template") template = null; 

  constructor() { }

  /***
   * 
   * Logo
   * 
   */
  setLogo(){ 

    this.logo = "/images/modulo/logo/"+this.data.params.imagem;
 
  } 
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.setLogo();
  }

}
