<div class="galeria-carrossel-images" *ngIf="template == null">
      <div class="content" #slider>
            <div *ngFor="let i of data;let y = index;" class="item" itemscope itemtype="http://schema.org/ImageObject">
                <img [src]="i.imagem" alt="i.titulo" class="img-fluid" itemprop="contentUrl" />
                <span style="display:none;" itemprop="description">{{i.titulo}}</span>
            </div> 
      </div> 
      <div class="swiper-button-prev sw-btn" (click)="prevSlide()">
            <img src="/images/icons/slider/prev.svg" alt="Prev" />
            <span class="bg"></span>
      </div>
      <div class="swiper-button-next sw-btn" (click)="nextSlide()"> 
            <img src="/images/icons/slider/next.svg" alt="Next" />
            <span class="bg"></span>
      </div>      
</div>   
<div class="galeria-carrossel-images default-two" *ngIf="template == 'two'">
      <div class="content" #slider>
            <div *ngFor="let i of data;let y = index;" class="item" itemscope itemtype="http://schema.org/ImageObject">
                <img [src]="i.imagem" alt="i.titulo" class="img-fluid" itemprop="contentUrl" />
                <span style="display:none;" itemprop="description">{{i.titulo}}</span>
            </div> 
      </div> 
      <div class="swiper-button-prev sw-btn" (click)="prevSlide()">
            <img src="/images/icons/slider/prev-02.svg" alt="Prev" />
      </div>
      <div class="swiper-button-next sw-btn" (click)="nextSlide()"> 
            <img src="/images/icons/slider/next-02.svg" alt="Next" />
      </div>      
</div>      