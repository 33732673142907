import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'galeria-carrossel-images',
  templateUrl: './galeria-carrossel-images.component.html',
  styleUrls: ['./galeria-carrossel-images.component.scss']
})
export class GaleriaCarrosselImagesComponent implements OnInit,AfterViewInit {

  @Input("data") data:any     = [];
  @ViewChild("slider") slider:ElementRef;
  @Input("template") template = null; 

  constructor(){}
  
  /***
   * 
   * Init slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({
      infinite: true,
      slidesToShow: 4,
      arrows: false,
      dots: false,
      responsive: [{
        breakpoint: 1224,
        settings: {
          slidesToShow: 4,  
        }
    },{
        breakpoint: 1024,
        settings: {
        slidesToShow: 2,
      }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,

        }
    },
    {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
        }
    }
  ]
    }); 
    $(this.slider.nativeElement).slick("refresh");

  }
  /***
   * 
   * Init slider
   * 
   */
  initSliderTwo(){

    $(this.slider.nativeElement).slick({
      infinite: true,
      slidesToShow: 1,
      arrows: false,
      dots: false,
      responsive: [{
        breakpoint: 1224,
        settings: {
          slidesToShow: 1,  
        }
    },{
        breakpoint: 1024,
        settings: {
        slidesToShow: 1,
      }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,

        }
    },
    {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          centerPadding: '0',
        }
    }
  ]
    }); 
    $(this.slider.nativeElement).slick("refresh");

  }
  /**
  * 
  * Prev
  * 
  **/
  prevSlide(){

    $(this.slider.nativeElement).slick("slickPrev");

  }
  /**
  * 
  * Next
  * 
  * 
  **/
  nextSlide(){

    $(this.slider.nativeElement).slick("slickNext"); 
  
  }
  /**
  * 
  * Init
  * 
  **/
  ngOnInit():void{
  }
  /***
   * 
   * After view loaded
   * 
   */
  ngAfterViewInit(): void {
    if(this.template == null){
      this.initSlider(); 
    }else if(this.template == 'two'){
      this.initSliderTwo();
    }
  }

}
