import { AfterViewInit, Component, ElementRef, Input, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var $:any;

@Component({
  selector: 'mapa-google',
  templateUrl: './mapa-google.component.html',
  styleUrls: ['./mapa-google.component.scss']
})
export class MapaGoogleComponent implements OnInit,AfterViewInit {

  @Input("latitude") latitude   = null;
  @Input("longitude") longitude = null;
  public url = null;
  @ViewChild("mapa") mapa:ElementRef;

  constructor(
    public sanitize: DomSanitizer
  ){ }
 

  /**
   * 
   * Set url
   * 
   */
  setMapa(){

    let html = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3985.224988072855!2d`+this.longitude+`!3d`+this.latitude+`!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f21fd721f7e67b%3A0x7a92f37eb77842bf!2zUG91c2FkYSBTw6NvIEpvc8Op!5e0!3m2!1spt-BR!2sbr!4v1610377526863!5m2!1spt-BR!2sbr" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`;

    $(this.mapa.nativeElement).html(html);

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setMapa();
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.setMapa();
  }

}
