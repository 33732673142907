import { Component, EventEmitter, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { GtagService } from 'src/app/services/gtag.service';

@Component({
  selector: 'acomodacoes-page',
  templateUrl: './acomodacoes-page.component.html',
  styleUrls: ['./acomodacoes-page.component.scss']
})
export class AcomodacoesPage implements OnInit {

  public loader       = false;
  private apelido     = "acomodacao"; 
  public response:any = {
    data: "",
    status: null
  };
  public page     = null;
  public fotos    = []; 
  public acomodacoes = [];
  public params:any = {};
  public itensBreadcrumbs = [];
  public openGaleria = new EventEmitter();

  constructor(
    private api:ApiService,
    private app: AppService
  ){}
  
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true; 
      
      this.api.conteudo().page(this.apelido).subscribe(response => {
      
        this.loader = false;
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page        = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos       = response.data.page.fotos;
            this.acomodacoes = response.data.page.acomodacoes;
  
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description);
              
          }
          this.response = { 
            data: "",
            status: 1
          }

          setTimeout(() => {
            this.app.toTop();   
          },500);
      
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }
  /***
   * 
   * Seta os breadcrumbs
   * 
   */
  setItensBreadcrumbs(){

    this.itensBreadcrumbs.push({
      text: "Sobre a Pousada"
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop();
    this.getData(); 
    this.setItensBreadcrumbs();
  }
  ngAfterViewInit(): void {
    
  }
  ngOnDestroy(): void {
  }

}
