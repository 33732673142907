<div class="modulo partial-menu">
      <h3 class="modulo-title">Links Úteis</h3>
      <ul>
            <li>
                  <a routerLink="/politicas-da-pousada" title="Políticas da Pousada">
                        Políticas da Pousada
                  </a>      
            </li>
            <li>
                  <a routerLink="/sobre-da-pousada" title="Sobre da Pousada">
                        Sobre da Pousada
                  </a>      
            </li>
            <li>
                  <a routerLink="/faca-sua-reserva" title="Faça sua reserva">
                        Faça sua Reserva
                  </a>      
            </li>
            <li>
                  <a routerLink="/contato" title="Contato">
                        Contato
                  </a>      
            </li>
      </ul>       
</div>      
