import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home-page/home-page.component';
import { SobrePage } from './pages/sobre-page/sobre-page.component';
import { ModulosResolver } from './resolvers/modulos.resolver';  
import { TrabalheConoscoPage } from './pages/trabalhe-conosco-page/trabalhe-conosco-page.component';
import { PoliticaDeCancelamentoPage } from './pages/politica-de-cancelamento-page/politica-de-cancelamento-page.component';
import { PoliticaDeSegurancaPage } from './pages/politica-de-seguranca-page/politica-de-seguranca-page.component';
import { PoliticaDePrivacidadePage } from './pages/politica-de-privacidade-page/politica-de-privacidade-page.component';
import { ContatoPageComponent } from './pages/contato-page/contato-page.component'; 
import { BlogPage } from './pages/blog/blog-page/blog-page.component';
import { BlogItemPage } from './pages/blog/blog-item-page/blog-item-page.component';
import { DestinoPage } from './pages/destino-page/destino-page.component';
import { AcomodacoesPage } from './pages/acomodacao/acomodacoes-page/acomodacoes-page.component';
import { AcomodacaoPage } from './pages/acomodacao/acomodacao-page/acomodacao-page.component';

const routes: Routes = [{ 
  path: '', 
  component: HomePage, 
  resolve:{
    modulos: ModulosResolver  
  }
},{
  path: 'sobre-a-pousada',
  component: SobrePage, 
  resolve:{
    modulos: ModulosResolver
  }
},{
  path: 'acomodacoes',
  component: AcomodacoesPage,
  resolve:{ 
    modulos: ModulosResolver
  }
},{
  path: 'acomodacoes/:apelido',
  component: AcomodacaoPage,
  resolve:{ 
    modulos: ModulosResolver
  }
},{
  path: 'destino',
  component: DestinoPage,
  resolve:{ 
    modulos: ModulosResolver
  }
},{
  path: 'blog',
  component: BlogPage,
  resolve:{ 
    modulos: ModulosResolver
  }
},{
  path: 'blog/:apelido',
  component: BlogPage,
  resolve:{ 
    modulos: ModulosResolver
  }
},{
  path: 'blog/:apelido_categoria/:apelido',
  component: BlogItemPage,
  resolve:{ 
    modulos: ModulosResolver
  }
},{
  path: 'politicas-da-pousada',
  component: PoliticaDeCancelamentoPage, 
  resolve:{
    modulos: ModulosResolver
  }
},{
  path: 'politica-de-privacidade',
  component: PoliticaDePrivacidadePage, 
  resolve:{
    modulos: ModulosResolver
  }
},{
  path: 'contato',  
  component: ContatoPageComponent,  
  resolve:{
    modulos: ModulosResolver
  }
},{  
  path: '**',
  pathMatch: 'full',
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
