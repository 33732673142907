import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-link-destaques',
  templateUrl: './link-destaques.component.html',
  styleUrls: ['./link-destaques.component.scss']
})
export class LinkDestaquesComponent implements OnInit {

  @Input("data") data:any           = {};
  @Input("template") template:any   = "";
  @Input("classCustom") classCustom = "";

  constructor() { }

  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit():void{}

}
