import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'blog-partial-menu',
  templateUrl: './blog-partial-menu.component.html',
  styleUrls: ['./blog-partial-menu.component.scss']
})
export class BlogPartialMenuComponent implements OnInit {

  @Input("data") data       = [];
  @Input('apelido') apelido = null;

  constructor(){ }

  /**
   * 
   * Set apelido
   * 
   */
  setApelido(){

    if(this.apelido == null){

      this.apelido = this.data[0].apelido;
 
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setApelido();
  }

}
