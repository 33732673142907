import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { GtagService } from '../../services/gtag.service';
import { FbService } from '../../services/fb.service';
import { ApiService } from 'src/app/services/api.service';
import { ModuloService } from 'src/app/services/modulo.service';
import { error } from 'protractor';
import { ValidatorService } from 'src/app/services/validator.service';

declare var $:any;

@Component({
  selector: 'contato-page',
  templateUrl: './contato-page.component.html',
  styleUrls: ['./contato-page.component.scss']
})
export class ContatoPageComponent implements OnInit,AfterViewInit,OnDestroy {
  
  public loader   = false;
  private apelido = "contato";
  public response:any = {
    data: "",
    status: null
  };
  public page     = null;
  public fotos    = []; 
  public unidades = []; 
  @ViewChild("messageContato") messageContato: ElementRef;
  @ViewChild("telefoneNumero") telefoneNumero: ElementRef;
  @ViewChild("dataInicio") dataInicio: ElementRef;
  @ViewChild("dataFim") dataFim: ElementRef;
  public dataItem:any = {};
  public message = {
    data: "",
    class: "",
    show: false
  };
  public messageLoader = "";
  public isSendAnalytics = false;

  constructor(
    private app: AppService,
    private api: ApiService,
    private modulo: ModuloService,
    private zone: NgZone,
    private gtag: GtagService,
    private fb: FbService,
    private validator: ValidatorService
  ) { 
    this.initDataItem();
  }
  
  /**
   * 
   * Envia o contato por email
   * 
   * 
   */
  send(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.nome,"nome","- Informe o seu Nome.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o seu E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O e-mail informado é inválido.");
      this.validator.isEmpty(this.dataItem.telefone,"telefone","- Informe o seu Telefone.");
      this.validator.isEmpty(this.dataItem.assunto,"email","- Informe o seu Assunto.");
      this.validator.isEmpty(this.dataItem.mensagem,"mensagem","- Informe a sua mensagem.");

      if(!this.validator.passes()){

        this.app.info(this.validator.getMessagesHtml()); 
        return false;  

      }

      this.loader        = true;
      this.message.show  = false;
      this.messageLoader = "Enviando os dados..."; 
      this.gtag.submitContato();

      this.api.contato().store(this.dataItem).subscribe(response => {

        this.loader = false;

        switch(response.status){
          case 1:

            this.initDataItem();
            this.app.info("Sua mensagem foi enviada com sucesso. Em breve entraremos em contato.");

          break;
          case 0:

            let errors = this.app.getErrors([response.data]);
    
            this.app.info(errors);

          break;
          default:

            this.app.info("Houve um erro interno. Se o erro permanecer informe nossa equipe.");
   

          break;
        }

      }, (response) =>{

        this.loader        = false;
        let error          = this.api.formatError(response);

        this.app.info(error.message);

      });      

    }catch(e){

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      assunto: "",
      mensagem: "",
      tipo: 3,
      apelido: "contato"
    }

  }
  /**
   * 
   * 
   * 
   * 
   */
  getData(){

    try{
      
      this.loader = true;
      this.messageLoader = "Carregando Conteúdo...";

      this.api.conteudo().page(this.apelido).subscribe(response =>{
      
        this.loader = false;  
      
        if(response.status == 1){
                
          if(typeof(response.data.page) != "undefined"){
                  
            this.page      = typeof(response.data.page.data[0]) != "undefined" ? response.data.page.data[0] : response.data.page.data;
            this.fotos     = response.data.page.fotos;
            this.unidades  = response.data.page.unidades;
            this.app.setMetaDados(this.page.meta_title,this.page.meta_description,this.page.meta_keywords);
              
          }
          this.response = {
            data: "",
            status: 1
          }
          
          setTimeout(() => {
            this.setDatePicker();
          },200);
      
        }else{
          this.response = {
            data: response.data,
            status: 500
          }
        }
      
      },(err) =>{
      
        this.loader = false;
        this.response = err;
      
      });
    
    }catch(e){
      
    }

  }

  /**
   * 
   * Seta Date Picker
   * 
   * 
   */
  setDatePicker(){ 

    let dataIn:any  = null;
    let dataOut:any = null;
    let self        = this;

    dataIn = $(this.dataInicio.nativeElement).datepicker({
      dateFormat: "dd-mm-yy",
      onSelect: (data) => {
        
        this.dataItem.data_in = data;
        dataOut.datepicker("option","minDate",data);
        this.zone.run(() => {});
      
      }
    });

    dataOut = $(this.dataFim.nativeElement).datepicker({
      dateFormat: "dd-mm-yy",
      onSelect: (data) => {
        
        this.dataItem.data_out = data;
        dataIn.datepicker("option","maxDate",data);
        this.zone.run(() => {});

      }
    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.app.toTop(); 
    this.getData();
  }
  setDataIn(value){

    this.dataItem.data_in = value.target.value;

  }
  setDataOut(value){

    this.dataItem.data_out = value.target.value;

  }
  /**
   *
   * Send analytics
   *
   */
  sendAnalytics() {
    
    if(!this.isSendAnalytics) {
      this.isSendAnalytics = true;
      setTimeout(() => {
        this.gtag.sendPage();
        this.fb.init();
        this.fb.pageView();
        this.fb.lead();
      }, 100);
    }
    
  }
  /**
   * 
   * Iniciliza as Funções depois que a view carregou
   * 
   */
  ngAfterViewInit():void{
    
  }
  ngOnDestroy(): void {
    this.isSendAnalytics = false; 
  }

}
