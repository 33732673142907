import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'blog-slider',
  templateUrl: './blog-slider.component.html',
  styleUrls: ['./blog-slider.component.scss']
})
export class BlogSliderComponent implements OnInit,AfterViewInit {

  @Input("data") data = [];
  @ViewChild("slider") slider:ElementRef;

  constructor(){}

  /**
   * 
   * init slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 2,
      dots: false,
      arrows: false,
      centerMode: true,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
              slidesToShow: 2,
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    });
    $(this.slider.nativeElement).slick('refresh'); 


  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    $(this.slider.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
