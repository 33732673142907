<a routerLink="/acomodacoes/{{data.apelido}}" [title]="data.nome">
      <div class="acomodacao-item">
            <figure>
                  <img [src]="data.imagem" [alt]="data.nome" class="img-fluid" />
            </figure> 
            <div class="content">
                  <h4>{{data.nome}}</h4>  
                  <article [innerHtml]="data.min_descricao | safeHtml"></article>
            </div>    
      </div>
</a>      